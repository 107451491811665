import * as yup from 'yup';
import { RadarDataFields } from '../../radar';
import {
  AZIMUTH_MAX,
  AZIMUTH_MIN,
  ELEVATION_MAX,
  ELEVATION_MIN,
  LATITUDE_MAX,
  LATITUDE_MIN,
  LONGITUDE_MAX,
  LONGITUDE_MIN,
} from '../../radar/constants';

export const settingsRadarSchema = yup.object().shape({
  [RadarDataFields.SERIAL_NUMBER]: yup.string(),
  [RadarDataFields.LAT]: yup
    .number()
    .typeError('validate.enter_number')
    .test('max value', 'validate.max_value', (value) => {
      if (typeof value !== 'number' || value > LATITUDE_MAX) {
        return false;
      }

      return true;
    })
    .test('min value', 'validate.min_value', (value) => {
      if (typeof value !== 'number' || value < LATITUDE_MIN) {
        return false;
      }

      return true;
    })
    .required('validate.enter_lat'),
  [RadarDataFields.LON]: yup
    .number()
    .typeError('validate.enter_number')
    .test('max value', 'validate.max_value', (value) => {
      if (typeof value !== 'number' || value > LONGITUDE_MAX) {
        return false;
      }

      return true;
    })
    .test('min value', 'validate.min_value', (value) => {
      if (typeof value !== 'number' || value < LONGITUDE_MIN) {
        return false;
      }

      return true;
    })
    .required('validate.enter_long'),

  [RadarDataFields.EL]: yup
    .number()
    .typeError('validate.enter_number')
    .test('elevation format', 'validate.between', (value) => {
      if (typeof value !== 'number') {
        return false;
      }
      if (value < ELEVATION_MIN || value > ELEVATION_MAX) {
        return false;
      }
      return true;
    })
    .required('validate.enter_elevation'),
  [RadarDataFields.AZ]: yup
    .number()
    .typeError('validate.enter_number')
    .test('direction format', 'validate.between', (value) => {
      if (typeof value !== 'number') {
        return false;
      }
      if (value < AZIMUTH_MIN || value > AZIMUTH_MAX) {
        return false;
      }
      return true;
    })
    .required('validate.enter_direction'),
});
