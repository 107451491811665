import settings_common from './settings-common.json';
import validation from './validation.json';
import object from './object.json';
import common from './common.json';
import radar from './radar.json';
import settings_radar from './settings-radar.json';

export default {
  'locales.en': 'English',
  'locales.uk': 'Українська',
  ...validation,
  ...settings_common,
  ...settings_radar,
  ...object,
  ...common,
  ...radar,
};
