export const RADAR_IMAGE_PROPERTIES = {
  radarSize: 1000,
  radiusStepsCount: 10,
  radarPadding: 0,
  radarCenter: 500,
};

export const KEY_CODES = {
  ARROW_UP: 38,
  ARROW_DOWN: 40,
  ARROW_RIGHT: 39,
  ARROW_LEFT: 37,
  KEY_Q: 81,
  KEY_A: 65,
  KEY_Z: 90,
};

export const LATITUDE_MIN = -90;
export const LATITUDE_MAX = 90;

export const LONGITUDE_MIN = -180;
export const LONGITUDE_MAX = 180;

export const ANGLE_MIN = -360;
export const ANGLE_MAX = 360;

export const AZIMUTH_MIN = -360;
export const AZIMUTH_MAX = 360;

export const ELEVATION_MIN = -45;
export const ELEVATION_MAX = 90;
