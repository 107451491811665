import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useModal } from 'mui-modal-provider';
import { memo, useCallback } from 'react';
import SettingsModal from './settings-modal';

function SettingsButton() {
  const { showModal } = useModal();

  const handleClick = useCallback(() => {
    showModal(SettingsModal);
  }, [showModal]);

  return (
    <IconButton
      color="primary"
      onClick={handleClick}
      size="large"
      sx={{ marginRight: 'auto' }}
    >
      <SettingsIcon />
    </IconButton>
  );
}

export default memo(SettingsButton);
