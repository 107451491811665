import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import AlbumRoundedIcon from '@mui/icons-material/AlbumRounded';
import TocIcon from '@mui/icons-material/Toc';
import { memo, useCallback, useEffect } from 'react';
import { Root } from './record.styled';
import CustomMenu from '../../../app/components/custom-menu';
import RecordList from './record-list';
import RecordIcon from '../../../app/icons/record-icon';
import { useAppDispatch, useAppSelector } from '../../../app/store/store';
import { selectHost } from '../../common';
import { selectPlayer, selectRecord } from '../../record/selectors';
import {
  addRecordData,
  onPlay,
  removeRecord,
  updateRecordsData,
} from '../../record/slice';
import RecordPlayer from './record-player';
import apiClient from '../../../app/utils/api-client';
import { ResponseLogFiles } from '../../record/type';
import { setSelectedObject } from '../../radar';

function Record() {
  const host = useAppSelector(selectHost);
  const dispatch = useAppDispatch();
  const { track_log_file, track_record, downloads, records } =
    useAppSelector(selectRecord);
  const { on } = useAppSelector(selectPlayer);

  const getLogFiles = useCallback(async () => {
    apiClient
      .post(`${host}/simulator/`, { method: 'log_files' })
      .then((response) => {
        dispatch(
          updateRecordsData({
            records: response.data.log_files.map(
              (file: ResponseLogFiles) => file.name,
            ),
          }),
        );
      });
  }, [host, dispatch]);

  useEffect(() => {
    getLogFiles();
  }, [getLogFiles]);

  const sendRecord = useCallback(
    (recordStatus: boolean) =>
      apiClient.post(`${host}/simulator/`, {
        method: recordStatus ? 'start_record' : 'stop_record',
      }),
    [host],
  );

  const handleToggleRecord = useCallback(async () => {
    if (track_record) {
      getLogFiles();
    }
    sendRecord(!track_record);
  }, [sendRecord, track_record, getLogFiles]);

  const handleDownload = useCallback(
    (record: string) => {
      apiClient
        .post(`${host}/simulator/`, { method: 'log_by_name', name: record })
        .then((response) => {
          const data = response.data
            .split(/\r\n|\r|\n/g)
            .filter((string: string) => string !== '')
            .map((string: string) => JSON.parse(string));

          dispatch(
            addRecordData({
              [record]: data,
            }),
          );
        });
    },
    [host, dispatch],
  );

  const handleOnPlay = useCallback(
    (record: string) => {
      dispatch(setSelectedObject(undefined));
      dispatch(onPlay(record));
    },
    [dispatch],
  );

  const handleDelete = useCallback(
    (record: string) => {
      apiClient
        .post(`${host}/simulator/`, { method: 'del_log_by_name', name: record })
        .then(() => {
          dispatch(removeRecord(record));
        });
    },
    [host, dispatch],
  );

  if (on) {
    return <RecordPlayer />;
  }

  return (
    <Root className={track_record ? 'record' : ''}>
      <Stack
        alignItems="center"
        direction="row"
        gap={1}
        justifyContent="space-between"
      >
        <Tooltip title={track_record ? 'Stop record' : 'Start record'}>
          <IconButton
            color={track_record ? 'warning' : 'secondary'}
            onClick={handleToggleRecord}
          >
            {track_record ? <RecordIcon /> : <AlbumRoundedIcon />}
          </IconButton>
        </Tooltip>
        <Box maxWidth="auto" overflow="hidden">
          <Typography
            color="primary.contrastText"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {track_log_file}
          </Typography>
        </Box>
        <CustomMenu
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          icon={<TocIcon />}
          iconColor="primary"
          sx={{ maxHeight: 400 }}
          tooltip="Records"
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <RecordList
            downloads={Object.keys(downloads)}
            onDelete={handleDelete}
            onDownload={handleDownload}
            onPlay={handleOnPlay}
            records={records}
          />
        </CustomMenu>
      </Stack>
    </Root>
  );
}

export default memo(Record);
