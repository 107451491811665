import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../app/store/store';
import {
  selectObjects,
  selectSelectedObject,
  setSelectedObject,
} from '../../radar';

export default function ObjectsInfo() {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const selectedId = useAppSelector(selectSelectedObject);
  const objects = useAppSelector(selectObjects);

  const handleClick = useCallback(
    (id: string) => {
      dispatch(setSelectedObject(id));
    },
    [dispatch],
  );

  return (
    <Stack gap={0.5} zIndex={500}>
      <Accordion>
        <AccordionSummary>
          <Typography
            color="primary.contrastText"
            textAlign="end"
            textTransform="uppercase"
            variant="h4"
          >
            {formatMessage({ id: 'common.objects_data' })}
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          {objects.map((object) => (
            <Box
              key={object.id}
              onClick={() => handleClick(object.id)}
              sx={{
                cursor: 'pointer',
                background:
                  selectedId === object.id ? 'rgba(0, 0, 0, 0.4)' : '',
                borderRadius: '9px',
                padding: 1,
              }}
            >
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
              >
                <Typography color="primary.contrastText" variant="body2">
                  {object.id}
                </Typography>
                <Typography
                  color="primary.contrastText"
                  textAlign="end"
                  variant="h6"
                >
                  : {formatMessage({ id: `object.id` })}
                </Typography>
              </Stack>

              <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
              >
                <Typography color="primary.contrastText" variant="body2">
                  {object.rcs}
                </Typography>
                <Typography
                  color="primary.contrastText"
                  textAlign="end"
                  variant="h6"
                >
                  : {formatMessage({ id: `object.rcs` })}
                </Typography>
              </Stack>

              <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
              >
                <Typography color="primary.contrastText" variant="body2">
                  {String(object.is_show)}
                </Typography>
                <Typography
                  color="primary.contrastText"
                  textAlign="end"
                  variant="h6"
                >
                  : {formatMessage({ id: `object.is_show` })}
                </Typography>
              </Stack>
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}
