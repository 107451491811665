import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useAppSelector } from '../../../app/store/store';
import { selectObjectById, selectSelectedObject } from '../../radar';

export default function ShowButton({ onClick }: { onClick: () => void }) {
  const selectedId = useAppSelector(selectSelectedObject);
  const selectedObject = useAppSelector(selectObjectById(selectedId));

  if (!selectedObject) {
    return null;
  }

  return (
    <IconButton
      color="primary"
      onClick={onClick}
      size="large"
      sx={{ mr: 'auto' }}
    >
      {selectedObject?.is_show ? <VisibilityIcon /> : <VisibilityOffIcon />}
    </IconButton>
  );
}
