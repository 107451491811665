import { RootState } from '../../app/store/store';

export function selectRadarData(state: RootState) {
  return state.radar.radar;
}

export function selectObjects(state: RootState) {
  return state.radar.objects;
}

export function selectObjectById(id: string | undefined) {
  return (state: RootState) =>
    state.radar.objects.find((object) => object.id === id);
}

export function selectSelectedObject(state: RootState) {
  return state.radar.selectedObject;
}

export function selectAreas(state: RootState) {
  return state.radar.areas;
}

export function selectRadar(state: RootState) {
  return state.radar;
}
