import { useTheme } from '@mui/material';
import { RadarTargetStyled } from './radar-target.styled';
import { ObjectData } from '../types';
import { RADAR_IMAGE_PROPERTIES } from '../constants';
import { calculateCoordinates } from '../utils';

export interface TargetProps extends ObjectData {
  active: boolean;
  targetSize: number;
  az: number;
  grid_range: number;
  direction: number;
  isReplay?: boolean;
  onClick: () => void;
}

function RadarTarget({
  active,
  targetSize,
  is_show,
  grid_range,
  dist_horizon,
  az,
  isReplay,
  direction,
  onClick,
  ...target
}: TargetProps) {
  const { palette } = useTheme();
  const halfTargetSize = targetSize / 2;
  const inactiveColor = is_show ? palette.primary.light : palette.info.dark;
  const activeColor = is_show ? palette.warning.main : palette.info.dark;

  const { radarCenter } = RADAR_IMAGE_PROPERTIES;
  const pxInMeter = radarCenter / (grid_range * 1000);

  const { x, y } = calculateCoordinates(
    isReplay ? az + direction : az,
    dist_horizon,
  );

  const object_x = x === 0 ? radarCenter : pxInMeter * x + radarCenter;
  const object_y = y === 0 ? radarCenter : pxInMeter * y + radarCenter;

  return (
    <RadarTargetStyled
      fill={active ? activeColor : inactiveColor}
      height={targetSize}
      onClick={onClick}
      stroke={palette.warning.main}
      width={targetSize}
      x={object_x - halfTargetSize}
      y={object_y - halfTargetSize}
      {...target}
    />
  );
}

export default RadarTarget;
