export enum RadarDataFields {
  SERIAL_NUMBER = 'serial_number',
  SCAN = 'scan',
  LAT = 'lat',
  LON = 'lon',
  AZ = 'az',
  EL = 'el',
  SCAN_AZ_START = 'scan_az_start',
  SCAN_AZ_END = 'scan_az_end',
  SCAN_EL_START = 'scan_el_start',
  SCAN_EL_END = 'scan_el_end',
  ALTITUDE = 'altitude',
  GRID_ANGLE = 'grid_angle',
  GRID_RANGE = 'grid_range',
  INSTRUMENTAL_RANGE = 'instrumental_range',
}

export interface RadarData {
  [RadarDataFields.SERIAL_NUMBER]: string;
  [RadarDataFields.LAT]: number;
  [RadarDataFields.LON]: number;
  [RadarDataFields.AZ]: number;
  [RadarDataFields.EL]: number;
  [RadarDataFields.ALTITUDE]: number;
  [RadarDataFields.SCAN_AZ_START]: number;
  [RadarDataFields.SCAN_AZ_END]: number;
  [RadarDataFields.SCAN_EL_START]: number;
  [RadarDataFields.SCAN_EL_END]: number;
  [RadarDataFields.SCAN]?: string;
}

export interface ObjectData {
  id: string;
  rad_speed: number;
  dist_horizon: number;
  object_x: number;
  object_y: number;
  altitude: number;
  az: number;
  is_show: boolean;
  max_speed: number;
  acceleration: number;
  rcs: number;
  precision_rcs: number;
  precision_position: number;
}

export interface AreasData {
  id: string;
  dist_horizon: number;
  object_x: number;
  object_y: number;
  az: number;
  radius: number;
}

export interface RadarSliceState {
  radar: RadarData;
  objects: ObjectData[];
  areas: AreasData[];
  selectedObject?: string;
}

export interface Coords {
  x: number;
  y: number;
}
