import { createSlice } from '@reduxjs/toolkit';
import { Features } from '../types';
import { RecordSliceState } from './type';

const initialData: RecordSliceState['data'] = {
  track_record: false,
  track_log_file: '',
  records: [],
  downloads: {},
  temp: [],
  player: {
    on: false,
    status: 'stop',
    step: 0,
  },
};

const initialState: RecordSliceState = {
  data: initialData,
};

const recordSlice = createSlice({
  name: Features.RECORD,
  initialState,
  reducers: {
    resetRecordData: (state) => {
      state.data = initialData;
    },
    updateRecordsData: (state, { payload }) => {
      state.data = { ...state.data, ...payload };
    },
    addRecordData: (state, { payload }) => {
      state.data.downloads = {
        ...state.data.downloads,
        ...payload,
      };
    },
    removeRecord: (state, { payload }) => {
      const copyDownloads = { ...state.data.downloads };
      delete copyDownloads[payload];
      state.data = {
        ...state.data,
        downloads: copyDownloads,
        records: state.data.records.filter((record) => record !== payload),
      };
    },
    onPlay: (state, { payload }) => {
      state.data.player = {
        on: true,
        played_file: payload,
        status: 'play',
        step: 0,
      };
    },
    onPause: (state) => {
      state.data.player = {
        ...state.data.player,
        status: 'pause',
      };
    },
    onResume: (state) => {
      state.data.player = {
        ...state.data.player,
        status: 'play',
      };
    },
    onStop: (state) => {
      state.data.player = {
        ...state.data.player,
        status: 'stop',
        step: 0,
      };
    },
    offRecord: (state) => {
      state.data.player = {
        on: false,
        status: 'pause',
        played_file: undefined,
        step: 0,
      };
    },
    incrementStep: (state) => {
      if (
        state.data.player.played_file &&
        state.data.player.step <
          state.data.downloads[state.data.player.played_file].length - 1
      ) {
        state.data.player = {
          ...state.data.player,
          step: state.data.player.step + 1,
        };
      } else {
        state.data.player = {
          ...state.data.player,
          status: 'stop',
          step: 0,
        };
      }
    },
    decrementStep: (state) => {
      if (state.data.player.played_file && state.data.player.step > 0) {
        state.data.player = {
          ...state.data.player,
          step: state.data.player.step - 1,
        };
      }
    },
  },
});

export const {
  resetRecordData,
  updateRecordsData,
  offRecord,
  onPause,
  onPlay,
  onResume,
  onStop,
  incrementStep,
  decrementStep,
  addRecordData,
  removeRecord,
} = recordSlice.actions;

export default recordSlice.reducer;
