import { createSlice } from '@reduxjs/toolkit';
import { Features } from '../types';
import { RadarSliceState } from './types';

const initialObjects = [
  {
    id: '145963',
    acceleration: 6,
    max_speed: 18,
    object_x: -872.9967901545193,
    object_y: 662.6406298891626,
    rcs: 0.01,
    precision_position: 1.8,
    precision_rcs: 0.003,
    is_show: false,
    az: 232.8,
    dist_horizon: 1096,
    altitude: 1,
    rad_speed: 0,
  },
  {
    id: '149025',
    acceleration: 6,
    max_speed: 18,
    object_x: -1498.92,
    object_y: 617.39,
    rcs: 0.01,
    precision_position: 1.8,
    precision_rcs: 0.003,
    is_show: false,
    az: 247.61,
    dist_horizon: 1621,
    altitude: 1,
    rad_speed: 0,
  },
  {
    id: '149042',
    acceleration: 6,
    max_speed: 18,
    object_x: -701.21,
    object_y: 1468.13,
    rcs: 0.01,
    precision_position: 1.8,
    precision_rcs: 0.003,
    is_show: false,
    az: 205.53,
    dist_horizon: 1627,
    altitude: 1,
    rad_speed: 0,
  },
  {
    id: '149402',
    acceleration: 6,
    max_speed: 18,
    object_x: -3716.6,
    object_y: 1700.0462,
    rcs: 0.01,
    precision_position: 1.8,
    precision_rcs: 0.003,
    is_show: false,
    az: 240.9,
    dist_horizon: 2734,
    altitude: 1,
    rad_speed: 0,
  },

  {
    id: '149404',
    acceleration: 6,
    max_speed: 18,
    object_x: -1,
    object_y: 1,
    rcs: 0.01,
    precision_position: 1.8,
    precision_rcs: 0.003,
    is_show: false,
    az: 225,
    dist_horizon: 1,
    altitude: 1,
    rad_speed: 0,
  },
];

const initialAreas = [
  {
    id: '145963',
    object_x: -872.9967901545193,
    object_y: 662.6406298891626,
    az: 232.8,
    dist_horizon: 1096,
    radius: 500,
  },
  {
    id: '149025',
    object_x: -1498.92,
    object_y: 617.39,
    az: 247.61,
    dist_horizon: 1621,
    radius: 500,
  },
  {
    id: '149042',
    object_x: -701.21,
    object_y: 1468.13,
    az: 205.53,
    dist_horizon: 1627,
    radius: 500,
  },
  {
    id: '149402',
    object_x: -3716.6,
    object_y: 1700.0462,
    az: 240.9,
    dist_horizon: 2734,
    radius: 500,
  },
];

const initialRadar = {
  serial_number: '123456',
  lat: 49.325175,
  lon: 32.779351,
  az: 0,
  el: 12.2,
  altitude: 0,
  scan_az_start: -45,
  scan_az_end: 45,
  scan_el_start: 0,
  scan_el_end: 40,
};

const initialState: RadarSliceState = {
  radar: initialRadar,
  objects: initialObjects,
  areas: initialAreas,
  selectedObject: undefined,
};

const radarSlice = createSlice({
  name: Features.RADAR,
  initialState,
  reducers: {
    resetData: (state) => {
      state.objects = initialObjects;
      state.areas = initialAreas;
      state.radar = initialRadar;
    },
    setSelectedObject(state, data) {
      state.selectedObject =
        state.selectedObject === data.payload ? undefined : data.payload;
    },
    updateObjectData(state, data) {
      state.objects = state.objects.map((object) =>
        object.id === data.payload.id
          ? {
              ...object,
              ...data.payload,
            }
          : object,
      );
    },
    updateObjects(state, data) {
      state.objects = state.objects.map((object) => {
        const foundObject = data.payload.find(
          (payloadObject: { id: string }) => payloadObject.id === object.id,
        );
        return foundObject ? { ...object, ...foundObject } : object;
      });
    },
    rewriteObjects(state, data) {
      state.objects = data.payload;
    },
    updateRadarData(state, data) {
      state.radar = { ...state.radar, ...data.payload };
    },

    rewriteAreas(state, data) {
      state.areas = data.payload;
    },
  },
});

export const {
  resetData,
  setSelectedObject,
  updateObjectData,
  rewriteObjects,
  updateRadarData,
  updateObjects,
  rewriteAreas,
} = radarSlice.actions;

export default radarSlice.reducer;
