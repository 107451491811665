import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

export interface MuiTypographyProps {
  defaultProps?: ComponentsProps['MuiTypography'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTypography'];
  variants?: ComponentsVariants['MuiTypography'];
}

const MuiTypography: MuiTypographyProps = {
  defaultProps: {},
  styleOverrides: {
    root: {},
    h1: ({ theme }) => ({
      fontWeight: 700,
      fontSize: '1.675rem',
      lineHeight: '115%',
      [theme.breakpoints.down('xl')]: {
        fontSize: '1.5rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.375rem',
      },
    }),
    h2: ({ theme }) => ({
      fontWeight: 700,
      fontSize: '1.5rem',
      lineHeight: '115%',
      [theme.breakpoints.down('xl')]: {
        fontSize: '1.375rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.25rem',
      },
    }),
    h3: ({ theme }) => ({
      fontWeight: 700,
      fontSize: '1.375rem',
      lineHeight: '115%',
      [theme.breakpoints.down('xl')]: {
        fontSize: '1.25rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125rem',
      },
    }),
    h4: ({ theme }) => ({
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: '115%',
      [theme.breakpoints.down('xl')]: {
        fontSize: '1.125rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    }),
    h5: ({ theme }) => ({
      fontWeight: 500,
      fontSize: '1.125rem',
      lineHeight: '115%',
      [theme.breakpoints.down('xl')]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    }),
    h6: ({ theme }) => ({
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '115%',
      [theme.breakpoints.down('xl')]: {
        fontSize: '0.875rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.65rem',
      },
    }),

    body1: ({ theme }) => ({
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '115%',
      [theme.breakpoints.down('xl')]: {
        fontSize: '0.875rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    }),
    body2: ({ theme }) => ({
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '115%',
      [theme.breakpoints.down('xl')]: {
        fontSize: '0.75rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.525rem',
      },
    }),
    subtitle1: ({ theme }) => ({
      fontWeight: 500,
      fontSize: '0.825rem',
      lineHeight: '115%',
      [theme.breakpoints.down('xl')]: {
        fontSize: '0.75rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.625rem',
      },
    }),
    subtitle2: ({ theme }) => ({
      fontWeight: 500,
      fontSize: '0.75rem',
      lineHeight: '115%',
      [theme.breakpoints.down('xl')]: {
        fontSize: '0.625rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.5rem',
      },
    }),
    caption: ({ theme }) => ({
      fontWeight: 300,
      fontSize: '0.75rem',
      lineHeight: '115%',
      [theme.breakpoints.down('xl')]: {
        fontSize: '0.625rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.5rem',
      },
    }),
  },
};

export default MuiTypography;
