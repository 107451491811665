const palette = {
  primary: {
    main: '#FFD233',
    light: '#2ECC71',
    dark: '#ff7300',
    contrastText: '#fff',
  },
  secondary: {
    main: '#323337',
    light: '#272727',
    dark: '#ff7300',
  },
  warning: {
    main: '#eb2b02',
  },
  info: {
    dark: '#dcdcdc',
    main: '#fff',
  },
  divider: 'rgba(255, 255, 255, 0.2)',
  background: {
    paper: '#272727',
    default: '#242529',
  },
};

export default palette;
