import { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  ModalProps,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { RadarData, RadarDataFields, updateRadarData } from '../../radar';
import {
  AZIMUTH_MAX,
  AZIMUTH_MIN,
  ELEVATION_MAX,
  ELEVATION_MIN,
  LATITUDE_MAX,
  LATITUDE_MIN,
  LONGITUDE_MAX,
  LONGITUDE_MIN,
} from '../../radar/constants';
import { settingsRadarSchema } from '../schemas/radar-settings';
import { useAppDispatch, useAppSelector } from '../../../app/store/store';
import { selectHost } from '../../common';
import apiClient from '../../../app/utils/api-client';

export interface SettingsRadarProps extends RadarData {
  onClose?: ModalProps['onClose'];
}
function SettingsRadar({ onClose, ...props }: SettingsRadarProps) {
  const host = useAppSelector(selectHost);
  const { serial_number } = props;
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<RadarData>({
    mode: 'onChange',
    defaultValues: props,
    resolver: yupResolver(settingsRadarSchema),
  });

  const getError = (
    key: keyof RadarData,
    options?: Record<string, unknown>,
  ) => {
    const hasError = Boolean(errors[key]);

    return {
      error: hasError,
      helperText: hasError
        ? formatMessage(
            {
              id: errors[key]?.message,
            },
            options,
          )
        : '',
    };
  };

  const onSubmit = useCallback(
    (formData: RadarData) => {
      dispatch(updateRadarData(formData));
      apiClient.post(`${host}/simulator/`, {
        method: 'radar_settings',
        radar: {
          serial_number: formData[RadarDataFields.SERIAL_NUMBER],
          lat: formData[RadarDataFields.LAT],
          lon: formData[RadarDataFields.LON],
          az: formData[RadarDataFields.AZ],
          el: formData[RadarDataFields.EL],
          altitude: formData[RadarDataFields.ALTITUDE],
        },
      });
      onClose?.({}, 'escapeKeyDown');
    },
    [dispatch, onClose, host],
  );

  return (
    <Stack
      component="form"
      gap={2}
      minHeight={400}
      onSubmit={handleSubmit(onSubmit)}
      py={3}
    >
      <Typography color="primary.contrastText">
        {formatMessage({
          id: 'settings.radar.label.serial_number',
        })}
        :{serial_number}
      </Typography>
      <Stack direction="row" gap={1}>
        <Box flex="1 1">
          <Stack direction="row" gap={1}>
            <TextField
              fullWidth
              inputProps={{
                pattern: '^\\d{0,5}(\\.\\d{0,6})?$',
                maxLength: 11,
                step: 0.000001,
                max: LATITUDE_MAX,
                min: LATITUDE_MIN,
              }}
              label={formatMessage({ id: 'settings.radar.label.latitude' })}
              placeholder="48.7687016"
              type="number"
              {...getError(RadarDataFields.LAT, {
                max: LATITUDE_MAX,
                min: LATITUDE_MIN,
              })}
              {...register(RadarDataFields.LAT)}
            />
            <TextField
              fullWidth
              inputProps={{
                pattern: '^\\d{0,5}(\\.\\d{0,6})?$',
                maxLength: 11,
                step: 0.000001,
                max: LONGITUDE_MAX,
                min: LONGITUDE_MIN,
              }}
              label={formatMessage({ id: 'settings.radar.label.longitude' })}
              placeholder="48.7687016"
              type="number"
              {...getError(RadarDataFields.LON, {
                max: LONGITUDE_MAX,
                min: LONGITUDE_MIN,
              })}
              {...register(RadarDataFields.LON)}
            />
          </Stack>
        </Box>
      </Stack>
      <Stack direction="row" gap={1}>
        <Box flex="1 1">
          <TextField
            fullWidth
            inputProps={{
              pattern: '[0-9,.]*',
              step: 0.1,
            }}
            label={formatMessage({
              id: 'settings.radar.label.azimuth',
            })}
            type="number"
            {...getError(RadarDataFields.AZ, {
              start: AZIMUTH_MIN,
              end: AZIMUTH_MAX,
            })}
            {...register(RadarDataFields.AZ, {
              valueAsNumber: true,
            })}
          />
        </Box>
        <Box flex="1 1">
          <TextField
            fullWidth
            inputProps={{
              pattern: '[0-9,.]*',
              step: 0.1,
            }}
            label={formatMessage({
              id: 'settings.radar.label.elevation',
            })}
            type="number"
            {...getError(RadarDataFields.EL, {
              start: ELEVATION_MIN,
              end: ELEVATION_MAX,
            })}
            {...register(RadarDataFields.EL, {
              valueAsNumber: true,
            })}
          />
        </Box>
        <Box flex="1 1">
          <TextField
            fullWidth
            inputProps={{
              pattern: '[0-9,.]*',
              step: 0.1,
            }}
            label={formatMessage({
              id: 'settings.radar.label.altitude',
            })}
            type="number"
            {...getError(RadarDataFields.ALTITUDE)}
            {...register(RadarDataFields.ALTITUDE, {
              valueAsNumber: true,
            })}
          />
        </Box>
      </Stack>
      <Button
        color="primary"
        sx={{ m: 'auto 0 0 auto' }}
        type="submit"
        variant="contained"
      >
        Save
      </Button>
    </Stack>
  );
}

export default memo(SettingsRadar);
