import { combineReducers } from '@reduxjs/toolkit';
import common from '../../features/common/slice';
import radar from '../../features/radar/slice';
import record from '../../features/record/slice';

import asyncRequestStateReducer, {
  REDUCER_NAME as ASYNC_REQUEST_STATE_REDUCER_NAME,
} from './async-request-state-reducer';

const appReducer = combineReducers({
  common,
  radar,
  record,
  [ASYNC_REQUEST_STATE_REDUCER_NAME]: asyncRequestStateReducer,
});

export default appReducer;
