import { memo, useEffect } from 'react';
import { Box, IconButton, Stack, TextField } from '@mui/material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import {
  ObjectData,
  rewriteObjects,
  selectObjects,
  selectSelectedObject,
  setSelectedObject,
  updateObjectData,
} from '../../radar';
import { useAppDispatch, useAppSelector } from '../../../app/store/store';
import { calculateAzimuthFromCoords } from '../../radar/utils';

export interface ObjectFormData extends ObjectData {
  uid: string;
}

export interface SettingsObjectsForm {
  objects: ObjectFormData[];
}

export interface SettingsObjectsProps {
  grid_range: number;
}

// function calculateAzAndDist(object_x = 0, object_y = 0, grid_range = 0) {
//   const pxInMeter = radarCenter / grid_range / 1000;

//   const x = object_x * pxInMeter + radarCenter;
//   const y = radarCenter - object_y * pxInMeter;
//   const dist_horizon = Math.sqrt(object_x ** 2 + object_y ** 2);

//   const az = radianToDegree(Math.atan2(y - radarCenter, x - radarCenter)) + 90;

//   return { az, dist_horizon };
// }

function SettingsObjects({ grid_range }: SettingsObjectsProps) {
  const dispatch = useAppDispatch();
  const selectedObjectId = useAppSelector(selectSelectedObject);
  const objects = useAppSelector(selectObjects);

  const { control, register } = useForm<SettingsObjectsForm>({
    mode: 'onChange',
    defaultValues: {
      objects: objects.map((object) => ({
        ...object,
        uid: object.id,
      })),
    },
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'objects',
  });

  const formData = useWatch({
    control,
  });

  function getId() {
    const currentTime = Math.round(new Date().getTime() / 100).toString();
    const subSting = currentTime.substring(5);
    return subSting;
  }

  useEffect(() => {
    dispatch(
      rewriteObjects(
        formData.objects?.map((object) => ({
          ...object,
          ...calculateAzimuthFromCoords(object.object_x, object.object_y),
          id: object.uid,
        })),
      ),
    );
  }, [formData, dispatch, grid_range]);

  return (
    <Stack component="form" gap={2} minHeight={400} py={3}>
      <SimpleBar style={{ maxHeight: 300, paddingRight: 20 }}>
        <Stack gap={2}>
          {fields.map((field, index) => {
            const selected = field.uid === selectedObjectId;
            return (
              <Stack key={field.id} borderBottom="1ps solid #eee" gap={1}>
                <Box flex="1 1">
                  <Stack direction="row" gap={1}>
                    <TextField
                      disabled={selected}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      label="ID"
                      {...register(`objects.${index}.uid`)}
                    />
                    <TextField
                      disabled={selected}
                      fullWidth
                      label="RCS, m2"
                      {...register(`objects.${index}.rcs`, {
                        valueAsNumber: true,
                      })}
                    />
                    <TextField
                      disabled={selected}
                      fullWidth
                      label="X, m"
                      {...register(`objects.${index}.object_x`, {
                        valueAsNumber: true,
                      })}
                    />
                    <TextField
                      disabled={selected}
                      fullWidth
                      label="Y, m"
                      {...register(`objects.${index}.object_y`, {
                        valueAsNumber: true,
                      })}
                    />
                  </Stack>
                </Box>
                <Box flex="1 1">
                  <Stack direction="row" gap={1}>
                    <TextField
                      disabled={selected}
                      fullWidth
                      label="Vmax, m/s"
                      {...register(`objects.${index}.max_speed`, {
                        valueAsNumber: true,
                      })}
                    />
                    <TextField
                      disabled={selected}
                      fullWidth
                      label="Amax, m/s2"
                      {...register(`objects.${index}.acceleration`, {
                        valueAsNumber: true,
                      })}
                    />
                    <TextField
                      disabled={selected}
                      fullWidth
                      label="RCS error, m2"
                      {...register(`objects.${index}.precision_rcs`, {
                        valueAsNumber: true,
                      })}
                    />
                    <TextField
                      disabled={selected}
                      fullWidth
                      label="Position error, m"
                      {...register(`objects.${index}.precision_position`, {
                        valueAsNumber: true,
                      })}
                    />
                  </Stack>
                </Box>
                <Box flex="1 1">
                  <Stack
                    alignItems="flex-end"
                    direction="row"
                    gap={1}
                    justifyContent="space-between"
                  >
                    <Stack direction="row" gap={1}>
                      <IconButton
                        color={selected ? 'primary' : 'secondary'}
                        onClick={() => dispatch(setSelectedObject(field.uid))}
                      >
                        {selected ? (
                          <RadioButtonCheckedIcon />
                        ) : (
                          <RadioButtonUncheckedIcon />
                        )}
                      </IconButton>
                      <IconButton
                        color={field.is_show ? 'primary' : 'secondary'}
                        onClick={() => {
                          update(index, { ...field, is_show: !field.is_show });

                          dispatch(
                            updateObjectData({
                              id: field.uid,
                              is_show: !field.is_show,
                            }),
                          );
                        }}
                      >
                        {field?.is_show ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </Stack>

                    <IconButton
                      color="error"
                      onClick={() => {
                        const removedObject = fields[index];

                        if (removedObject.uid === selectedObjectId) {
                          dispatch(setSelectedObject(removedObject.uid));
                        }

                        remove(index);
                      }}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Stack>
                </Box>
              </Stack>
            );
          })}
        </Stack>
      </SimpleBar>
      <IconButton
        color="primary"
        onClick={() => {
          const object_x = 0;
          const object_y = 100;

          append({
            uid: getId(),
            id: getId(),
            acceleration: 6,
            max_speed: 18,
            object_x,
            object_y,
            rcs: 0.01,
            precision_position: 0.003,
            precision_rcs: 1.8,
            is_show: false,
            ...calculateAzimuthFromCoords(object_x, object_y),
            altitude: 1,
            rad_speed: 1,
          });
        }}
      >
        <PlaylistAddIcon />
      </IconButton>
    </Stack>
  );
}

export default memo(SettingsObjects);
