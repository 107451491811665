import { useEffect, useMemo, useRef, useState } from 'react';

export default function useResponsive() {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerSize, setContainerSize] = useState(0);

  const ro = useMemo(
    () =>
      new ResizeObserver((entries) => {
        const { width, height } = entries[0].target.getBoundingClientRect();
        const size = width < height ? width : height;
        setContainerSize(size);
      }),
    [],
  );

  useEffect(() => {
    if (containerRef.current) {
      ro.observe(containerRef.current);
    }
  }, [ro, containerRef]);

  return { containerRef, containerSize };
}
