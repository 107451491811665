import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useAppSelector } from '../../../app/store/store';
import { RadarDataFields, selectRadarData } from '../../radar';

export default function RadarInfo() {
  const { formatMessage } = useIntl();
  const data = useAppSelector(selectRadarData);

  return (
    <Stack gap={0.5}>
      <Accordion variant="elevation">
        <AccordionSummary>
          <Typography
            color="primary.contrastText"
            textAlign="center"
            textTransform="uppercase"
            variant="h4"
          >
            {formatMessage({ id: 'common.radar_data' })}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack alignItems="center" direction="row">
            <Typography color="primary.contrastText" variant="h6">
              {formatMessage({ id: RadarDataFields.SERIAL_NUMBER })}
            </Typography>
            <Typography color="primary.contrastText" variant="body2">
              : {data[RadarDataFields.SERIAL_NUMBER]}
            </Typography>
          </Stack>
          <Stack alignItems="center" direction="row">
            <Typography color="primary.contrastText" variant="h6">
              {formatMessage({ id: RadarDataFields.SCAN })}
            </Typography>
            <Typography color="primary.contrastText" variant="body2">
              : {data[RadarDataFields.SCAN]}
            </Typography>
          </Stack>
          <Stack alignItems="center" direction="row">
            <Typography color="primary.contrastText" variant="h6">
              {formatMessage({ id: RadarDataFields.LAT })}
            </Typography>
            <Typography color="primary.contrastText" variant="body2">
              : {data[RadarDataFields.LAT]}
            </Typography>
          </Stack>
          <Stack alignItems="center" direction="row">
            <Typography color="primary.contrastText" variant="h6">
              {formatMessage({ id: RadarDataFields.LON })}
            </Typography>
            <Typography color="primary.contrastText" variant="body2">
              : {data[RadarDataFields.LON]}
            </Typography>
          </Stack>
          <Stack alignItems="center" direction="row">
            <Typography color="primary.contrastText" variant="h6">
              {formatMessage({ id: RadarDataFields.AZ })}
            </Typography>
            <Typography color="primary.contrastText" variant="body2">
              : {data[RadarDataFields.AZ]}
            </Typography>
          </Stack>
          <Stack alignItems="center" direction="row">
            <Typography color="primary.contrastText" variant="h6">
              {formatMessage({ id: RadarDataFields.EL })}
            </Typography>
            <Typography color="primary.contrastText" variant="body2">
              : {data[RadarDataFields.EL]}
            </Typography>
          </Stack>
          <Stack alignItems="center" direction="row">
            <Typography color="primary.contrastText" variant="h6">
              {formatMessage({ id: RadarDataFields.ALTITUDE })}
            </Typography>
            <Typography color="primary.contrastText" variant="body2">
              : {data[RadarDataFields.ALTITUDE]}
            </Typography>
          </Stack>
          <Stack alignItems="center" direction="row">
            <Typography color="primary.contrastText" variant="h6">
              {formatMessage({ id: 'scan_az' })}
            </Typography>
            <Typography color="primary.contrastText" variant="body2">
              : [{data[RadarDataFields.SCAN_AZ_START]},
              {data[RadarDataFields.SCAN_AZ_END]}]
            </Typography>
          </Stack>
          <Stack alignItems="center" direction="row">
            <Typography color="primary.contrastText" variant="h6">
              {formatMessage({ id: 'scan_el' })}
            </Typography>
            <Typography color="primary.contrastText" variant="body2">
              : [{data[RadarDataFields.SCAN_EL_START]},
              {data[RadarDataFields.SCAN_EL_END]}]
            </Typography>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}
