import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import { PathsMap } from './app-router-paths';
import { DashboardLayout } from '../layouts';
import EmulatorScreen from '../../features/emulator/screens/emularor-screen';

export enum RouteType {
  COMMON = 'common',
  PUBLIC = 'public',
}

export interface Route {
  path: string;
  element: ReactElement;
  children?: Route[];
}

export type Routes = Record<RouteType, Route[]>;
export type Layouts = Record<RouteType, ReactElement>;

export const routes: Routes = {
  [RouteType.COMMON]: [],
  [RouteType.PUBLIC]: [
    {
      path: PathsMap.ANY,
      element: <EmulatorScreen />,
    },
  ],
};

export const layouts: Layouts = {
  [RouteType.PUBLIC]: <DashboardLayout />,
  [RouteType.COMMON]: <Outlet />,
};
