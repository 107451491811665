import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

export interface MuiTabsProps {
  defaultProps?: ComponentsProps['MuiTabs'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTabs'];
  variants?: ComponentsVariants['MuiTabs'];
}

const MuiTabs: MuiTabsProps = {
  defaultProps: {},
  styleOverrides: {
    root: () => ({
      '& .MuiButtonBase-root': {
        color: '#fff',
        flex: '1 1',
        justifyContent: 'flex-start',
        minHeight: 50,
      },
    }),
  },
};

export default MuiTabs;
