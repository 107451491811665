import { Routes, Route } from 'react-router-dom';
import { CustomRouter } from '../components';
import {
  routes,
  layouts,
  Route as IRoute,
  RouteType,
} from './app-router-config';
import { history } from '../utils/history';

export function renderRoute(
  { children, path, element }: IRoute,
  index: number,
  type: RouteType,
) {
  const hasChildren = children && children.length > 0;

  return (
    <Route key={String(index)} element={element} path={path}>
      {hasChildren
        ? children.map((child, j) => renderRoute(child, j, type))
        : null}
    </Route>
  );
}

export function renderRouteSet(type: RouteType, index: number) {
  return (
    <Route key={String(index)} element={layouts[type]}>
      {routes[type].map((route, i) => renderRoute(route, i, type))}
    </Route>
  );
}

export default function AppRouter() {
  const routeTypes = Object.values(RouteType);

  return (
    <CustomRouter history={history}>
      <Routes>{routeTypes.map(renderRouteSet)}</Routes>
    </CustomRouter>
  );
}
