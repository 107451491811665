import { createSlice } from '@reduxjs/toolkit';
import { Features } from '../types';
import { CommonSliceState, EmulatorType, LanguageField } from './types';

const initialState: CommonSliceState = {
  data: {
    language: LanguageField.EN,
    host: 'http://49.13.200.122:8001/api',
    map_url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    throttle: 2000,
    grid_range: 3,
    type: EmulatorType.MAVIC,
    deceleration: 5,
  },
};

const commonSlice = createSlice({
  name: Features.COMMON,
  initialState,
  reducers: {
    setCommonData(state, data) {
      state.data = { ...state.data, ...data.payload };
    },
  },
});

export const { setCommonData } = commonSlice.actions;

export default commonSlice.reducer;
