import { Stack, TextField, Typography } from '@mui/material';
import { useCallback } from 'react';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { useAppDispatch, useAppSelector } from '../../../app/store/store';
import { selectThrottle, setCommonData } from '../../common';
import CustomMenu from '../../../app/components/custom-menu';

export interface RecordPlayerThrottleProps {
  disabled?: boolean;
}

export default function RecordPlayerThrottle({
  disabled,
}: RecordPlayerThrottleProps) {
  const dispatch = useAppDispatch();
  const throttle = useAppSelector(selectThrottle);

  const handleChange = useCallback(
    (event) => {
      dispatch(
        setCommonData({
          throttle: event.target.value,
        }),
      );
    },
    [dispatch],
  );

  return (
    <Stack alignItems="center" direction="row">
      <Typography color="primary.contrastText">
        Delay: {throttle / 1000}s
      </Typography>
      <CustomMenu
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        disabled={disabled}
        icon={<DriveFileRenameOutlineIcon />}
        iconColor="info"
        iconSize="small"
        slotProps={{
          root: {
            sx: { p: 0 },
          },
        }}
        tooltip="Edit throttle"
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <TextField
          color="info"
          onChange={handleChange}
          value={throttle}
          variant="filled"
        />
      </CustomMenu>
    </Stack>
  );
}
