import { useTheme } from '@mui/material';
import { AreasData } from '../types';
import { RADAR_IMAGE_PROPERTIES } from '../constants';
import { calculateCoordinates } from '../utils';

export interface AreaProps extends AreasData {
  az: number;
  grid_range: number;
}

function RadarArea({ grid_range, dist_horizon, az, radius }: AreaProps) {
  const { palette } = useTheme();

  const { radarCenter } = RADAR_IMAGE_PROPERTIES;
  const pxInMeter = radarCenter / (grid_range * 1000);

  const { x, y } = calculateCoordinates(az, dist_horizon);

  const object_x = x === 0 ? radarCenter : pxInMeter * x + radarCenter;
  const object_y = y === 0 ? radarCenter : pxInMeter * y + radarCenter;

  return (
    <circle
      cx={object_x}
      cy={object_y}
      fill="none"
      r={radius * pxInMeter}
      stroke={palette.warning.main}
    />
  );
}

export default RadarArea;
