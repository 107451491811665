import { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/store/store';
import {
  selectObjectById,
  selectRadarData,
  selectSelectedObject,
} from '../selectors';
import { ObjectData, updateObjectData } from '..';
import { KEY_CODES } from '../constants';
import {
  calculateAzimuthFromCoords,
  calculateCoordinates,
  calculateVelocity,
  getDirectionMaxSpeed,
  radianToDegree,
} from '../utils';
import { selectCommonData, selectGridRange } from '../../common';
import { randFloat } from '../../../app';

export interface Directions {
  h: number;
  x: number;
  y: number;
  distance: number;
  distanceH: number;
}

export interface SpeedData {
  v_x: number;
  v_y: number;
  v_h: number;
  v_xy: number;
  speed: number;
  v_az: number;
  v_el: number;
}

let currentDirections: Directions = {
  x: 0,
  y: 0,
  h: 0,
  distance: 0,
  distanceH: 0,
};

let object_speed: SpeedData = {
  v_x: 0,
  v_y: 0,
  v_h: 0,
  v_xy: 0,
  speed: 0,
  v_az: 0,
  v_el: 0,
};

let cords = {
  x: 0,
  y: 0,
  h: 0,
};

const timer = 100;
const mod = timer / 1000;

const temp = {
  x: 0,
  y: 0,
  h: 0,
};

export interface FlyObject extends ObjectData {
  x: number;
  y: number;
}

export default function useObjectMove() {
  const ref = useRef<string | null>(null);
  const dispatch = useAppDispatch();
  const { type, deceleration } = useAppSelector(selectCommonData);
  const selectedObjectId = useAppSelector(selectSelectedObject);
  const selectedObject = useAppSelector(selectObjectById(selectedObjectId));
  const { az, altitude } = useAppSelector(selectRadarData);
  const grid_range = useAppSelector(selectGridRange);

  useEffect(() => {
    if (selectedObject) {
      if (ref.current !== selectedObject.id) {
        const calculateCoords = calculateCoordinates(
          selectedObject.az,
          selectedObject.dist_horizon,
        );

        cords = {
          ...calculateCoords,
          h: selectedObject.altitude,
        };

        object_speed = {
          v_x: 0,
          v_y: 0,
          v_h: 0,
          v_xy: 0,
          speed: 0,
          v_az: 0,
          v_el: 0,
        };

        ref.current = selectedObject.id;
      }
    } else {
      ref.current = null;
    }
  }, [selectedObject, az, grid_range]);

  const speedCalculate = useCallback(
    (object) => {
      const { v_x, v_y, v_h, speed, v_xy } = object_speed;
      const { acceleration, max_speed } = object;

      object_speed.speed = Math.sqrt(v_x ** 2 + v_y ** 2 + v_h ** 2);
      object_speed.v_xy = Math.sqrt(v_x ** 2 + v_y ** 2);

      object_speed.v_x = calculateVelocity({
        axis: currentDirections.x,
        velocity: v_x,
        acceleration,
        deceleration,
        mod,
        max_speed: getDirectionMaxSpeed(
          max_speed,
          currentDirections.x,
          currentDirections.distance,
        ),
        speed,
        type,
      });

      object_speed.v_y = calculateVelocity({
        axis: currentDirections.y,
        velocity: v_y,
        acceleration,
        deceleration,
        mod,
        max_speed: getDirectionMaxSpeed(
          max_speed,
          currentDirections.y,
          currentDirections.distance,
        ),
        speed,
        type,
      });

      object_speed.v_h = calculateVelocity({
        axis: currentDirections.h,
        velocity: v_h,
        acceleration,
        deceleration,
        mod,
        max_speed: getDirectionMaxSpeed(
          max_speed,
          currentDirections.h,
          currentDirections.distanceH,
        ),
        speed,
        type,
      });

      if (v_x !== 0) {
        if (v_y === 0 && v_x > 0) {
          object_speed.v_az = 0;
        } else if (v_y === 0 && v_x < 0) {
          object_speed.v_az = 180;
        } else if (v_y > 0 && v_x < 0) {
          object_speed.v_az = radianToDegree(Math.atan(v_y / v_x)) + 180;
        } else if (v_y < 0 && v_x < 0) {
          object_speed.v_az = radianToDegree(Math.atan(v_y / v_x)) - 180;
        } else {
          object_speed.v_az = radianToDegree(Math.atan(v_y / v_x));
        }
      } else {
        if (v_y > 0) {
          object_speed.v_az = 90;
        }
        if (v_y < 0) {
          object_speed.v_az = -90;
        }
        if (v_y === 0) {
          object_speed.v_az = 0;
        }
      }

      if (v_h !== 0) {
        if (v_h > 0 && speed === 0) {
          object_speed.v_el = 90;
        } else if (v_h < 0 && speed === 0) {
          object_speed.v_el = -90;
        } else if (v_h > 0) {
          object_speed.v_el = radianToDegree(Math.acos(v_xy / speed));
        } else if (v_h < 0) {
          object_speed.v_el = -radianToDegree(Math.acos(v_xy / speed));
        }
      } else {
        object_speed.v_el = 0;
      }
    },
    [type, deceleration],
  );

  const update = useCallback(() => {
    if (selectedObject) {
      speedCalculate(selectedObject);

      const calcNewH = cords.h + object_speed.v_h * mod;

      cords.x += object_speed.v_x * mod;
      cords.y += object_speed.v_y * mod;
      cords.h = calcNewH < 0 ? 0 : calcNewH;

      const dist =
        Math.sqrt(cords.x ** 2 + cords.y ** 2 + (cords.h - altitude) ** 2) /
        2000;

      const Δ = dist * selectedObject.precision_position;

      const x = cords.x + randFloat(-Δ, Δ);
      const y = cords.y + randFloat(-Δ, Δ);
      const h = cords.h + randFloat(-Δ, Δ);

      dispatch(
        updateObjectData({
          id: selectedObject.id,
          altitude: h,
          object_x: cords.x,
          object_y: cords.y,
          ...calculateAzimuthFromCoords(x, y),
        }),
      );
    }
  }, [speedCalculate, dispatch, selectedObject, altitude]);

  useEffect(() => {
    let interval: NodeJS.Timer | null = null;
    if (selectedObjectId) {
      interval = setInterval(update, timer);
    }

    return () => {
      if (selectedObjectId && interval) {
        clearInterval(interval);
      }
    };
  }, [update, selectedObjectId]);

  const toggleIsShow = useCallback(() => {
    if (selectedObject) {
      dispatch(
        updateObjectData({
          id: selectedObject.id,
          is_show: !selectedObject.is_show,
        }),
      );
    }
  }, [selectedObject, dispatch]);

  const setMoveDirection = (direction: Partial<Directions>) => {
    currentDirections = { ...currentDirections, ...direction };
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const key = event.which;

      switch (key) {
        case KEY_CODES.ARROW_UP:
          temp.y = -1;
          break;
        case KEY_CODES.ARROW_DOWN:
          temp.y = 1;
          break;
        case KEY_CODES.ARROW_RIGHT:
          temp.x = 1;
          break;
        case KEY_CODES.ARROW_LEFT:
          temp.x = -1;
          break;
        case KEY_CODES.KEY_Q:
          temp.h = 1;
          break;
        case KEY_CODES.KEY_A:
          temp.h = -1;
          break;
        case KEY_CODES.KEY_Z:
          toggleIsShow();
          break;
        default:
          break;
      }
      const commonAbs = Math.abs(temp.x) + Math.abs(temp.y) + Math.abs(temp.h);

      if (commonAbs === 0) {
        currentDirections.y = 0;
        currentDirections.x = 0;
        currentDirections.h = 0;
      } else {
        currentDirections.y = temp.y / commonAbs;
        currentDirections.x = temp.x / commonAbs;
        currentDirections.h = temp.h / commonAbs;
      }
    },
    [toggleIsShow],
  );

  const handleKeyUp = useCallback((event: KeyboardEvent) => {
    const key = event.which;

    switch (key) {
      case KEY_CODES.ARROW_UP:
      case KEY_CODES.ARROW_DOWN:
        temp.y = 0;
        break;
      case KEY_CODES.ARROW_RIGHT:
      case KEY_CODES.ARROW_LEFT:
        temp.x = 0;
        break;
      case KEY_CODES.KEY_Q:
      case KEY_CODES.KEY_A:
        temp.h = 0;
        break;
      default:
        break;
    }

    const commonAbs = Math.abs(temp.x) + Math.abs(temp.y) + Math.abs(temp.h);

    if (commonAbs === 0) {
      currentDirections.y = 0;
      currentDirections.x = 0;
      currentDirections.h = 0;
    } else {
      currentDirections.y = temp.y / commonAbs;
      currentDirections.x = temp.x / commonAbs;
      currentDirections.h = temp.h / commonAbs;
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  return {
    setMoveDirection,
    object_speed,
    toggleIsShow,
    currentDirections,
  };
}
