import palette from '../../app/theme/palette';
import { EmulatorType } from '../common';
import { RADAR_IMAGE_PROPERTIES } from './constants';
import { Directions } from './hook/object-move';

export function getSectorPath({
  startX,
  startY,
  radius,
  begin,
  end,
}: {
  startX: number;
  startY: number;
  radius: number;
  begin: number;
  end: number;
}) {
  const x1 = startX + radius * Math.cos((Math.PI * begin) / 180);
  const y1 = startY + radius * Math.sin((Math.PI * begin) / 180);
  const x2 = startX + radius * Math.cos((Math.PI * end) / 180);
  const y2 = startY + radius * Math.sin((Math.PI * end) / 180);

  return `M ${startX} ${startY} L ${x1} ${y1} A ${radius} ${radius} 0 0 1 ${x2} ${y2} Z`;
}

export function getTargetDirectionByElevation(elevation: number) {
  if (elevation >= 4000) {
    return palette.warning.main;
  }
  if (elevation >= 1000) {
    return palette.primary.dark;
  }
  if (elevation >= 100) {
    return palette.primary.main;
  }
  if (elevation >= 0) {
    return palette.primary.light;
  }

  return 'transparent';
}

export const radiusSteps = [1, 1.5, 3, 5, 7, 10, 15, 20, 25, 30, 40, 50];

export const getZoom = (pxInMeter: number, latitude: number) => {
  const equatorLengthInMeter = 40075016.686; // 2π ∙ 6378137

  const latitudeLengthInMeter =
    equatorLengthInMeter * Math.cos(latitude * (Math.PI / 180));

  const zoom = Math.log2(latitudeLengthInMeter / pxInMeter) - 8;

  return Number(zoom.toFixed(1));
};

export enum ChangeInputEnum {
  INCREMENT = 'increment',
  DECREMENT = 'decrement',
}

export const changeInputNumberValue = (
  prevValue: number,
  type: ChangeInputEnum,
  callback: (newValue: number) => void,
  maxValue: number,
) => {
  if (type === ChangeInputEnum.DECREMENT) {
    if (Number.isNaN(prevValue)) {
      callback(-0.1);
    }
    if (prevValue > maxValue) {
      callback(prevValue ? Number((prevValue - 0.1).toFixed(1)) : -0.1);
    }
  }

  if (type === ChangeInputEnum.INCREMENT) {
    if (Number.isNaN(prevValue)) {
      callback(0.1);
    }
    if (prevValue < maxValue) {
      callback(prevValue ? Number((prevValue + 0.1).toFixed(1)) : 0.1);
    }
  }
};

export const changeLatLongValue = (
  prevValue: number,
  type: ChangeInputEnum,
  callback: (newValue: number) => void,
  maxValue: number,
) => {
  if (type === ChangeInputEnum.DECREMENT) {
    if (Number.isNaN(prevValue)) {
      callback(-0.000001);
    }
    if (prevValue > maxValue) {
      callback(
        prevValue ? Number((prevValue - 0.000001).toFixed(6)) : -0.000001,
      );
    }
  }

  if (type === ChangeInputEnum.INCREMENT) {
    if (Number.isNaN(prevValue)) {
      callback(0.000001);
    }
    if (prevValue < maxValue) {
      callback(
        prevValue ? Number((prevValue + 0.000001).toFixed(6)) : 0.000001,
      );
    }
  }
};

export function calculateDirection(
  currentPoint: number[],
  previousPoint: number[],
) {
  const dx = currentPoint[0] - previousPoint[0];
  const dy = currentPoint[1] - previousPoint[1];
  const angle = Math.atan2(dy, dx);

  return angle * (180 / Math.PI);
}

export function isCoordinates(input: string) {
  const regex =
    /^-?([1-8]?[0-9](\.\d+)?|90(\.0+)?),\s*-?((1[0-7]|[1-9])?[0-9](\.\d+)?|180(\.0+)?)$/;

  return regex.test(input);
}

export function isCoordinatesSeparatedByCommas(input: string) {
  const regexComa =
    /^-?([1-8]?[0-9](,\d+)?|90(\.0+)?),\s*-?((1[0-7]|[1-9])?[0-9](,\d+)?|180(\.0+)?)$/;

  return regexComa.test(input);
}

export function replaceCommaWithDot(coordinates: string) {
  const parts = coordinates.split(', ');
  return parts.map((part) => part.replace(',', '.')).join(', ');
}

export function getRadarPaddingByContainerSize(containerSize: number) {
  return (
    (containerSize / RADAR_IMAGE_PROPERTIES.radarSize) *
    (RADAR_IMAGE_PROPERTIES.radarPadding * 2)
  );
}

export function degreeToRadian(deg: number) {
  return deg * (Math.PI / 180);
}

export function radianToDegree(rad: number) {
  return rad * (180 / Math.PI);
}

export function calculateAzimuth(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
) {
  const φ1 = degreeToRadian(lat1);
  const φ2 = degreeToRadian(lat2);
  const Δλ = degreeToRadian(lon2 - lon1);

  const y = Math.sin(Δλ) * Math.cos(φ2);
  const x =
    Math.cos(φ1) * Math.sin(φ2) - Math.sin(φ1) * Math.cos(φ2) * Math.cos(Δλ);
  const θ = Math.atan2(y, x);

  const azimuth = (radianToDegree(θ) + 360) % 360;

  return azimuth;
}

export function calculateDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
) {
  const R = 6371e3; // Радиус Земли в метрах

  const φ1 = degreeToRadian(lat1);
  const φ2 = degreeToRadian(lat2);
  const Δφ = degreeToRadian(lat2 - lat1);
  const Δλ = degreeToRadian(lon2 - lon1);

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;

  return distance;
}

export function calculateLat(
  radar_lat: number,
  targetAz: number,
  dist_horizon: number,
) {
  const R = 6371e3; // Радиус Земли в метрах
  const φ1 = degreeToRadian(radar_lat);
  const δ = (dist_horizon * 1000) / R;

  const lat = Math.asin(
    Math.sin(φ1) * Math.cos(δ) +
      Math.cos(φ1) * Math.sin(δ) * Math.cos(targetAz),
  );
  return radianToDegree(lat);
}

export function calculateLon(
  radar_lon: number,
  radar_lat: number,
  lat: number,
  targetAz: number,
  dist_horizon: number,
) {
  const R = 6371e3; // Радиус Земли в метрах
  const φ1 = degreeToRadian(radar_lat);
  const λ1 = degreeToRadian(radar_lon);
  const δ = (dist_horizon * 1000) / R;

  const lon =
    λ1 +
    Math.atan2(
      Math.sin(targetAz) * Math.sin(δ) * Math.cos(φ1),
      Math.cos(δ) - Math.sin(φ1) * Math.sin(lat),
    );
  return radianToDegree(lon);
}

export interface CalculateVelocity {
  axis: number;
  velocity: number;
  acceleration: number;
  deceleration: number;
  mod: number;
  max_speed: number;
  speed: number;
  type: EmulatorType;
}

export function calculateVelocity({
  acceleration,
  axis,
  deceleration,
  max_speed,
  mod,
  type,
  velocity,
}: CalculateVelocity) {
  if (axis === 0 && type === EmulatorType.MAVIC) {
    return 0;
  }

  if (axis === 0 || Math.abs(velocity) > max_speed) {
    if (velocity > 0) {
      if (velocity - deceleration * mod > 0) {
        return velocity - deceleration * mod;
      }

      return 0;
    }
    if (velocity < 0) {
      if (velocity + deceleration * mod < 0) {
        return velocity + deceleration * mod;
      }

      return 0;
    }
  } else if (Math.abs(velocity) < max_speed) {
    return velocity + axis * acceleration * mod;
  }

  return velocity;
}

export function calculateCoordinates(azimuth: number, distance: number) {
  const x = distance * Math.cos(degreeToRadian(azimuth - 90));
  const y = distance * Math.sin(degreeToRadian(azimuth - 90));
  return { x, y };
}

export function calculateAzimuthFromCoords(x = 0, y = 0) {
  const dx = x;
  const dy = y;
  const distance = Math.sqrt(dx * dx + dy * dy);

  const azimuth = radianToDegree(Math.atan2(dy, dx)) + 90;

  return {
    az: Number(azimuth.toFixed(2)),
    dist_horizon: Number(distance.toFixed(0)),
  };
}

export function test(currentDirections: Directions, speed: number) {
  return {
    v_x: speed * Math.abs(currentDirections.x),
    v_y: speed * Math.abs(currentDirections.y),
    v_h: speed * Math.abs(currentDirections.h),
  };
}

export function getDirectionMaxSpeed(
  max_speed: number,
  direction: number,
  distance: number,
) {
  return Math.sqrt(max_speed ** 2 * Math.abs(direction)) * (distance / 100);
}
