import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

export interface MuiAccordionProps {
  defaultProps?: ComponentsProps['MuiAccordion'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiAccordion'];
  variants?: ComponentsVariants['MuiAccordion'];
}

const MuiAccordion: MuiAccordionProps = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      textTransform: 'none',
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxShadow: 'none',
      border: 'none',
      background: 'transparent',
      '&:before': {
        display: 'none',
      },
      '& .MuiAccordionSummary-root': {
        padding: '0 10px',
        borderRadius: 12,
        minHeight: 30,
        backgroundColor: theme.palette.background.paper,
        '&.Mui-expanded': {
          minHeight: '54px',
        },
        '& .MuiAccordionSummary-content': {
          justifyContent: 'center',
          '&.Mui-expanded': {
            margin: '15px 0',
          },
        },
      },
      '& .MuiAccordionDetails-root': {
        padding: 5,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        borderRadius: 12,
      },
    }),
  },
};

export default MuiAccordion;
