import { Stack, styled } from '@mui/material';

export const PlayerWrapper = styled(Stack)(({ theme }) => ({
  transition: theme.transitions.create(['width']),
  width: '330px',
  gap: 8,
}));

export const Root = styled(Stack)(() => ({
  backgroundColor: '#00000089',
  borderRadius: 5,
  padding: 5,
}));
