import { useCallback } from 'react';
import { IconButton, MenuItem, Stack, Typography } from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

export interface RecordListProps {
  downloads: string[];
  records: string[];
  onPlay: (record: string) => void;
  onDownload: (record: string) => void;
  onDelete: (record: string) => void;
}

export default function RecordList({
  downloads,
  records,
  onDownload,
  onPlay,
  onDelete,
}: RecordListProps) {
  const renderRecord = useCallback(
    (record: string) => {
      const isDownloaded = downloads.includes(record);

      return (
        <MenuItem key={record}>
          <Stack alignItems="center" direction="row" gap={2}>
            <Typography color="primary.contrastText">{record}</Typography>
            <IconButton
              color={isDownloaded ? 'primary' : 'secondary'}
              onClick={() =>
                isDownloaded ? onPlay(record) : onDownload(record)
              }
              size="small"
            >
              {isDownloaded ? <PlayCircleIcon /> : <DownloadForOfflineIcon />}
            </IconButton>
            <IconButton
              color="error"
              onClick={() => onDelete(record)}
              size="small"
            >
              <DeleteForeverIcon />
            </IconButton>
          </Stack>
        </MenuItem>
      );
    },
    [downloads, onPlay, onDownload, onDelete],
  );

  return records.length ? (
    <>{records.map(renderRecord)}</>
  ) : (
    <Stack>
      <Typography color="primary.contrastText">Empty record data</Typography>
    </Stack>
  );
}
