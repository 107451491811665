import { useTheme } from '@mui/material';
import { RadarTargetStyled } from './radar-target.styled';
import { RADAR_IMAGE_PROPERTIES } from '../constants';
import { calculateCoordinates } from '../utils';
import { RecordObjectData } from '../../record/type';

export interface RadarRecordTargetProps extends RecordObjectData {
  targetSize: number;
  grid_range: number;
  radarAz: number;
  radarEl: number;
}

function RadarRecordTarget({
  targetSize,
  grid_range,
  az,
  el,
  range,
  radarAz,
  radarEl,
  ...target
}: RadarRecordTargetProps) {
  const { palette } = useTheme();
  const halfTargetSize = targetSize / 2;
  const { radarCenter } = RADAR_IMAGE_PROPERTIES;
  const pxInMeter = radarCenter / (grid_range * 1000);
  const dist_horizon = range * Math.abs(Math.cos(el / 300 + radarEl));
  const { x, y } = calculateCoordinates(az / 300 + radarAz, dist_horizon);
  const object_x = x === 0 ? radarCenter : pxInMeter * x + radarCenter;
  const object_y = y === 0 ? radarCenter : pxInMeter * y + radarCenter;

  return (
    <RadarTargetStyled
      fill={palette.warning.main}
      height={targetSize}
      width={targetSize}
      x={object_x - halfTargetSize}
      y={object_y - halfTargetSize}
      {...target}
    />
  );
}

export default RadarRecordTarget;
