import { Outlet } from "react-router-dom";
import { Root } from "./dashboard-layout.styled";

export default function DashboardLayout() {
  return (
    <Root>
      <Outlet />
    </Root>
  );
}
