import { throttle } from 'lodash';
import { useCallback, useEffect } from 'react';
import { formatObjectData } from '../../../app';
import useResponsive from '../../../app/hooks/use-responsive';
import logger from '../../../app/services/logger-service';
import { useAppDispatch, useAppSelector } from '../../../app/store/store';
import apiClient from '../../../app/utils/api-client';
import { selectCommonData } from '../../common';
import {
  selectObjectById,
  selectRadar,
  selectRadarData,
  selectSelectedObject,
  setSelectedObject,
  updateObjects,
  updateRadarData,
} from '../../radar';
import Radar from '../../radar/components/radar';
import useObjectMove from '../../radar/hook/object-move';
import useZoom from '../../radar/hook/use-zoom';
import {
  selectDownloadRecords,
  selectPlayer,
  selectRecord,
} from '../../record/selectors';
import { updateRecordsData } from '../../record/slice';
import HeightJoystick from '../components/height-joystick';
import Map from '../components/map';
import ObjectSpeed from '../components/object-speed';
import ObjectsInfo from '../components/objects-info';
import RadarInfo from '../components/radar-info';
import Record from '../components/record';
import SettingsButton from '../components/settings-button';
import ShowButton from '../components/show-button';
import SpeedJoystick from '../components/speed-joystick';
import {
  HeightJoystickWrapper,
  InfoWrapper,
  ObjectSpeedWrapper,
  RecordWrapper,
  Root,
  SpeedJoystickWrapper,
} from './emulator-screen.styled';

export default function EmulatorScreen() {
  const dispatch = useAppDispatch();
  const { on: isReplay, step, played_file } = useAppSelector(selectPlayer);
  const downloads = useAppSelector(selectDownloadRecords);
  const { objects, radar } = useAppSelector(selectRadar);
  const { host, throttle: delay } = useAppSelector(selectCommonData);
  const selectedObjectId = useAppSelector(selectSelectedObject);
  const selectedObject = useAppSelector(selectObjectById(selectedObjectId));
  const { az } = useAppSelector(selectRadarData);
  const { track_record } = useAppSelector(selectRecord);
  const { containerRef, containerSize } = useResponsive();

  const { object_speed, setMoveDirection, toggleIsShow, currentDirections } =
    useObjectMove();

  const { radarRef, offsetPercent, viewBox, zoomRatio } = useZoom({
    maxZoomRatio: 3,
    mouseSensitive: 0.03,
    buttonSensitive: 10,
  });

  const sendObject = useCallback(
    throttle(
      (body) => {
        apiClient
          .post(`${host}/simulator/`, body)
          .then((response) => {
            const { data } = response;
            if (data.radar) {
              dispatch(updateRadarData(data.radar));
            }
            if (data.objects) {
              dispatch(updateObjects(data.objects));
            }
            if (data.record) {
              dispatch(updateRecordsData(data.record));
            }
          })
          .catch((error) => {
            logger.info(error);
          });
      },
      delay,
      { leading: false },
    ),
    [dispatch, host, delay, track_record],
  );

  const handleClick = useCallback(
    (id: string) => {
      if (!isReplay) {
        dispatch(setSelectedObject(id));
      }
    },
    [dispatch, isReplay],
  );

  useEffect(() => {
    if (!isReplay) {
      const formatObjects = objects.map((object) =>
        formatObjectData(object, selectedObjectId, object_speed, az),
      );

      sendObject({
        method: 'send_objects',
        objects: formatObjects,
      });
    }
  }, [objects, sendObject, selectedObjectId, object_speed, az, isReplay]);

  useEffect(() => {
    if (isReplay && downloads && played_file) {
      apiClient.post(
        `${host}/player/`,
        JSON.stringify(downloads[played_file][step]),
      );
    }
  }, [step, downloads, host, isReplay, played_file]);

  return (
    <Root ref={containerRef}>
      <InfoWrapper>
        <RadarInfo />
        {!isReplay && (
          <>
            <ObjectsInfo />
            <SettingsButton />
            <ShowButton onClick={toggleIsShow} />
          </>
        )}
      </InfoWrapper>
      <Map
        containerSize={containerSize}
        offsetPercent={offsetPercent}
        zoomRatio={zoomRatio}
      />
      <Radar
        ref={radarRef}
        isReplay={isReplay}
        onClick={handleClick}
        replayObjects={played_file ? downloads?.[played_file][step] : []}
        viewBox={viewBox}
        zoomRatio={zoomRatio}
        {...radar}
      />
      {!isReplay && (
        <>
          <SpeedJoystickWrapper>
            <SpeedJoystick
              currentDirections={currentDirections}
              setMoveDirection={setMoveDirection}
            />
          </SpeedJoystickWrapper>
          <HeightJoystickWrapper>
            <HeightJoystick
              currentDirections={currentDirections}
              setMoveDirection={setMoveDirection}
            />
          </HeightJoystickWrapper>
          <ObjectSpeedWrapper>
            <ObjectSpeed
              object_speed={object_speed}
              selectedObject={selectedObject}
            />
          </ObjectSpeedWrapper>
        </>
      )}

      <RecordWrapper>
        <Record />
      </RecordWrapper>
    </Root>
  );
}
