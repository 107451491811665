import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
  alpha,
} from '@mui/material';

export interface MuiIconButtonProps {
  defaultProps?: ComponentsProps['MuiIconButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiIconButton'];
  variants?: ComponentsVariants['MuiIconButton'];
}

const MuiIconButton: MuiIconButtonProps = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: '4px',
      '&:hover': {
        opacity: 0.9,
      },
      '&:disabled': {
        backgroundColor: theme.palette.secondary.main,
        color: alpha(theme.palette.primary.contrastText, 0.3),
        border: `1px solid ${alpha(theme.palette.primary.contrastText, 0.3)}`,
      },
    }),
    sizeMedium: ({ theme }) => ({
      width: 42,
      height: 42,
      [theme.breakpoints.down('xl')]: {
        width: 32,
        height: 32,
      },
      '& .MuiSvgIcon-root': {
        width: 32,
        height: 32,
        [theme.breakpoints.down('xl')]: {
          width: 24,
          height: 24,
        },
      },
    }),
    sizeSmall: ({ theme }) => ({
      width: 32,
      height: 32,
      [theme.breakpoints.down('xl')]: {
        width: 24,
        height: 24,
      },
      '& .MuiSvgIcon-root': {
        width: 26,
        height: 26,
        [theme.breakpoints.down('xl')]: {
          width: 18,
          height: 18,
        },
      },
    }),
    colorPrimary: ({ theme }) => ({
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    }),
    colorSecondary: ({ theme }) => ({
      backgroundColor: alpha(theme.palette.primary.light, 0.2),
      border: `1px solid ${theme.palette.primary.light}`,
      color: theme.palette.primary.light,
    }),
    colorInfo: () => ({
      '&:disabled': {
        backgroundColor: 'transparent',
        border: 'none',
      },
    }),
    colorError: ({ theme }) => ({
      backgroundColor: alpha(theme.palette.warning.main, 0.2),
      border: `1px solid ${theme.palette.warning.main}`,
      color: theme.palette.warning.main,
    }),
    colorSuccess: ({ theme }) => ({
      backgroundColor: alpha(theme.palette.primary.light, 0.2),
      border: `1px solid ${theme.palette.primary.light}`,
      color: theme.palette.primary.light,
    }),
  },
};

export default MuiIconButton;
