import { RootState } from '../../app/store/store';
import { CommonDataField } from './types';

export function selectCommonData(state: RootState) {
  return state.common.data;
}

export function selectLanguage(state: RootState) {
  return state.common.data[CommonDataField.LANGUAGE];
}

export function selectHost(state: RootState) {
  return state.common.data[CommonDataField.HOST];
}

export function selectThrottle(state: RootState) {
  return state.common.data[CommonDataField.THROTTLE];
}

export function selectMapUrl(state: RootState) {
  return state.common.data[CommonDataField.MAP_URL];
}

export function selectGridRange(state: RootState) {
  return state.common.data[CommonDataField.GRID_RANGE];
}
