import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import RadarIcon from '@mui/icons-material/Radar';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { Box, Dialog, DialogProps, Tab, Tabs } from '@mui/material';
import DraggablePaper from '../../../app/components/draggable-paper';
import SettingsObjects from './settings-objects';
import SettingsCommon from './settings-common';
import { useAppSelector } from '../../../app/store/store';
import { selectCommonData, selectGridRange } from '../../common';
import { selectRadarData } from '../../radar';
import SettingsRadar from './settings-radar';
import SettingsAreas from './settings-areas';

export default function SettingsModal({
  onClose,
  open,
  ...otherProps
}: DialogProps) {
  const { formatMessage } = useIntl();
  const grid_range = useAppSelector(selectGridRange);
  const common = useAppSelector(selectCommonData);
  const radar = useAppSelector(selectRadarData);
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const tabs = useMemo(
    () => [
      {
        label: 'common.radar',
        content: <SettingsRadar {...radar} onClose={onClose} />,
        icon: <RadarIcon />,
      },
      {
        label: 'common.objects',
        content: <SettingsObjects grid_range={grid_range} />,
        icon: <MyLocationIcon />,
      },
      {
        label: 'common.areas',
        content: <SettingsAreas grid_range={grid_range} />,
        icon: <LocationSearchingIcon />,
      },
      {
        label: 'common.common',
        content: <SettingsCommon {...common} />,
        icon: <DisplaySettingsIcon />,
      },
    ],
    [common, radar, grid_range, onClose],
  );

  return (
    <Dialog
      hideBackdrop
      keepMounted
      onClose={onClose}
      open={open}
      PaperComponent={DraggablePaper}
      {...otherProps}
    >
      <div id="draggable-dialog">
        <Tabs
          aria-label="vertical-tabs"
          onChange={handleChange}
          scrollButtons="auto"
          value={value}
          variant="scrollable"
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              aria-controls={`vertical-tabpanel-${tab.label}`}
              icon={tab.icon}
              iconPosition="start"
              id={`vertical-tab-${tab.label}`}
              label={formatMessage({ id: tab.label })}
            />
          ))}
        </Tabs>
        {tabs.map(({ content }, index) => (
          <Box
            key={index}
            aria-labelledby={`vertical-tab-${value}`}
            hidden={value !== index}
            id={`vertical-tabpanel-${value}`}
            role="tabpanel"
          >
            {value === index && content}
          </Box>
        ))}
      </div>
    </Dialog>
  );
}
