import { useTheme } from '@mui/material';
import _ from 'lodash';
import { useSpring, animated, config } from '@react-spring/web';
import { memo } from 'react';
import { RADAR_IMAGE_PROPERTIES } from '../constants';
import { getSectorPath } from '../utils';

const { radarCenter, radarSize } = RADAR_IMAGE_PROPERTIES;

interface RadarViewRadiusProps {
  az: number;
  scan_az_end: number;
  scan_az_start: number;
  grid_angle: number;
  grid_range: number;
  instrumental_range: number;
}

function RadarViewRadius({
  az,
  scan_az_end,
  scan_az_start,
  grid_angle,
  grid_range,
  instrumental_range,
}: RadarViewRadiusProps) {
  const { palette } = useTheme();

  const opacityAnimation = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    config: {
      duration: 1500,
    },
  });

  const rotateAnimation = useSpring({
    from: {
      rotate: 0,
    },
    to: {
      rotate: 360,
      transformOrigin: 'center center',
    },
    config: config.molasses,
    delay: 0,
    loop: false,
  });

  if (_.isNaN(az) || _.isNaN(grid_angle)) {
    return null;
  }

  const pxInKm = radarSize / grid_range;
  const viewRadius = pxInKm * (instrumental_range / 1000);

  const calcMaxVision = viewRadius > radarSize ? radarSize : viewRadius;

  const begin = az + (scan_az_start - 90) + grid_angle;
  const end = az + (scan_az_end - 90) + grid_angle;

  const x2 =
    radarCenter +
    (calcMaxVision / 2) * Math.cos((Math.PI * (begin + end)) / 360);
  const y2 =
    radarCenter +
    (calcMaxVision / 2) * Math.sin((Math.PI * (begin + end)) / 360);

  return (
    <g>
      <g opacity={0.2}>
        <animated.path
          d={getSectorPath({
            startX: radarCenter,
            startY: radarCenter,
            radius: calcMaxVision / 2,
            begin,
            end,
          })}
          fill="url(#gradient)"
          style={{ ...opacityAnimation, ...rotateAnimation }}
        />
      </g>
      <g opacity={0.7}>
        <animated.line
          stroke={palette.info.main}
          strokeDasharray="5,5"
          strokeWidth={1}
          style={{ ...opacityAnimation, ...rotateAnimation }}
          x1={radarCenter}
          x2={x2}
          y1={radarCenter}
          y2={y2}
        />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="gradient"
          x1={radarCenter}
          x2={radarCenter}
          y1={0}
          y2={radarCenter}
        >
          <stop stopColor={palette.primary.main} stopOpacity=".3" />
          <stop offset="1" stopColor={palette.primary.main} />
        </linearGradient>
      </defs>
    </g>
  );
}

export default memo(RadarViewRadius);
