import { styled } from '@mui/material';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100dvh',
  maxWidth: 4096,
  margin: '0 auto',
  backgroundColor: theme.palette.background.default,
}));
