import { styled } from '@mui/material';

export const RadarSVG = styled('svg')({
  // width: 'calc(100%)',
  // height: 'calc(100%)',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: `translate(-50%, -50%)`,
  overflow: 'hidden',
  userSelect: 'none',
  zIndex: 500,
});
