import { ObjectData } from '../../features/radar';
import { SpeedData } from '../../features/radar/hook/object-move';

export function capitalizeFirstLetter(string: string) {
  if (string.trim() === '') {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function excludeNaNFields(obj: Record<string, unknown>) {
  const filteredObj = Object.entries(obj).filter(
    ([, value]) => !Number.isNaN(value) && value !== null,
  );

  return Object.fromEntries(filteredObj);
}

export function toFixed(number: number, precision = 0) {
  const divider = 10 ** precision;

  return Math.round(number * divider) / divider;
}

export function randFloat(min: number, max: number) {
  const str = (Math.random() * (max - min) + min).toFixed(2);
  return parseFloat(str);
}

export function formatObjectData(
  object: ObjectData,
  selectedObjectId: string | undefined,
  object_speed: SpeedData,
  az: number,
) {
  return {
    id: object.id,
    rcs: toFixed(
      object.rcs +
        randFloat(
          -object.rcs * object.precision_rcs,
          object.rcs * object.precision_rcs,
        ),
      2,
    ),

    altitude: toFixed(object.altitude, 1),
    az: toFixed(object.az - az, 1),
    speed: toFixed(selectedObjectId === object.id ? object_speed.speed : 0, 2),
    speed_az: toFixed(
      selectedObjectId === object.id ? object_speed.v_az + 90 - az : 0 - az,
      2,
    ),
    speed_el: toFixed(
      selectedObjectId === object.id ? object_speed.v_el : 0,
      2,
    ),
    dist_horizon: toFixed(object.dist_horizon, 1),
    object_x: toFixed(object.object_x, 1),
    object_y: toFixed(object.object_y, 1),
    is_show: object.is_show,
  };
}
