import { styled } from "@mui/material";

export const Root = styled("div")({
  position: "relative",
});

export const Text = styled("p")(({ theme }) => ({
  margin: 0,
  fontWeight: 500,
  fontSize: 14,
  lineHeight: "120%",
  color: theme.palette.primary.contrastText,
}));
