import { uk as ua, enUS } from 'date-fns/locale';

export enum LanguageField {
  UK = 'uk',
  EN = 'en',
}

export enum EmulatorType {
  MAVIC = 'MAVIC',
  FPV = 'FPV',
}

export enum CommonDataField {
  LANGUAGE = 'language',
  HOST = 'host',
  MAP_URL = 'map_url',
  THROTTLE = 'throttle',
  GRID_RANGE = 'grid_range',
  TYPE = 'type',
  DECELERATION = 'deceleration',
}

export const locales = {
  [LanguageField.EN]: enUS,
  [LanguageField.UK]: ua,
};

export interface CommonData {
  [CommonDataField.LANGUAGE]: LanguageField;
  [CommonDataField.HOST]: string;
  [CommonDataField.MAP_URL]: string;
  [CommonDataField.THROTTLE]: number;
  [CommonDataField.GRID_RANGE]: number;
  [CommonDataField.TYPE]: EmulatorType;
  [CommonDataField.DECELERATION]: number;
}

export interface CommonSliceState {
  data: CommonData;
}
