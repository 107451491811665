import axios from 'axios';

export enum StatusCode {
  Ok = 200,
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}

const apiClient = axios.create({});

apiClient.interceptors.request.use(async (request) => request);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
);

export default apiClient;
