import { IconButton, Menu, MenuProps, Tooltip } from '@mui/material';
import { ReactElement, useState } from 'react';

export interface CustomMenuProps extends Omit<MenuProps, 'open'> {
  icon: ReactElement;
  tooltip?: string;
  iconColor?:
    | 'inherit'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  iconSize?: 'small' | 'medium' | 'large';
  disabled?: boolean;
}

export default function CustomMenu({
  icon,
  id,
  iconColor,
  iconSize,
  tooltip,
  disabled,
  children,
  ...otherProps
}: CustomMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={tooltip}>
        <span>
          <IconButton
            aria-controls={open ? `${id}-menu` : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            color={iconColor}
            disabled={disabled}
            onClick={handleClick}
            size={iconSize}
          >
            {icon}
          </IconButton>
        </span>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id={`${id}-menu`}
        onClose={handleClose}
        open={open}
        {...otherProps}
      >
        {children}
      </Menu>
    </>
  );
}
