import { ForwardedRef, forwardRef, memo } from 'react';
import { useTheme } from '@mui/material';
import { RadarSVG } from './radar.styled';
import RadarCenter from './radar-center';
import RadarViewRadius from './radar-view-radius';
import RadarTarget from './radar-target';
import RadarArea from './radar-area';
import { RecordObjectData } from '../../record/type';
import RadarRecordTarget from './radar-record-target';
import { useAppSelector } from '../../../app/store/store';
import { selectRadar } from '../selectors';
import { selectCommonData } from '../../common';

export interface RadarProps {
  replayObjects: RecordObjectData[];
  viewBox: string;
  zoomRatio: number;
  isReplay: boolean;
  onClick: (id: string) => void;
}

function Radar(
  { viewBox, onClick, replayObjects, zoomRatio, isReplay }: RadarProps,
  ref: ForwardedRef<SVGSVGElement | null>,
) {
  const { grid_range } = useAppSelector(selectCommonData);
  const { objects, areas, radar, selectedObject } = useAppSelector(selectRadar);
  const { az, el, scan_az_end, scan_az_start } = radar;
  const { palette } = useTheme();

  return (
    <RadarSVG
      ref={ref}
      fill={palette.primary.main}
      height="100%"
      viewBox={viewBox}
      width="100%"
    >
      <RadarCenter />
      <RadarViewRadius
        az={az}
        grid_angle={0}
        grid_range={grid_range}
        instrumental_range={40000}
        scan_az_end={scan_az_end}
        scan_az_start={scan_az_start}
      />
      <g>
        {areas.map((area, index) => (
          <RadarArea key={index} grid_range={grid_range} {...area} />
        ))}
      </g>
      <g>
        {isReplay
          ? replayObjects.map((object, index) => (
              <RadarRecordTarget
                key={index}
                grid_range={grid_range}
                radarAz={az}
                radarEl={el}
                targetSize={10 / zoomRatio}
                {...object}
              />
            ))
          : objects.map((object, index) => (
              <RadarTarget
                key={index}
                active={object.id ? selectedObject === object.id : false}
                direction={az}
                grid_range={grid_range}
                isReplay={isReplay}
                onClick={() => onClick(object?.id)}
                targetSize={10 / zoomRatio}
                {...object}
              />
            ))}
      </g>
    </RadarSVG>
  );
}

export default memo(forwardRef(Radar));
