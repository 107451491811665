import { AnyAction } from '@reduxjs/toolkit';
import slice from 'lodash/fp/slice';
import split from 'lodash/fp/split';
import join from 'lodash/fp/join';
import flow from 'lodash/flow';
import { AsyncRequestState } from '../store/types';
import { resetAsyncRequestState } from '../store/actions';

export const REDUCER_NAME = 'asyncRequestState';
const SEPARATOR = '/';

export default function asyncRequestStateReducer(
  state: Record<string, boolean>,
  { type, payload, meta }: AnyAction,
) {
  const typePrefix = flow(
    split(SEPARATOR),
    slice(0, -1),
    join(SEPARATOR),
  )(type);

  // Reset item in state using action
  if (type === resetAsyncRequestState.type && state[payload]) {
    return {
      ...state,
      [payload]: {
        [AsyncRequestState.PENDING]: false,
        [AsyncRequestState.REJECTED]: false,
        [AsyncRequestState.FULFILLED]: false,
      },
    };
  }

  // Skip all sync actions
  if (!typePrefix || !meta) {
    return {
      ...state,
    };
  }

  return {
    ...state,
    [typePrefix]: {
      [AsyncRequestState.PENDING]:
        meta.requestStatus === AsyncRequestState.PENDING,
      [AsyncRequestState.REJECTED]:
        meta.requestStatus === AsyncRequestState.REJECTED,
      [AsyncRequestState.FULFILLED]:
        meta.requestStatus === AsyncRequestState.FULFILLED,
    },
  };
}
