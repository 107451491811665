import { Stack, styled } from '@mui/material';

export const Root = styled(Stack)(({ theme }) => ({
  backgroundColor: '#00000089',
  borderRadius: 5,
  padding: 5,
  gap: 8,
  width: '90px',
  transition: theme.transitions.create(['width']),
  '&.record': {
    width: '290px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '110px',
    '&.record': {
      width: '320px',
    },
  },
}));
