import { memo } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { RadarMapContainer, RadarMapWrapper } from './map.styled';
import { Coords, selectRadarData } from '../../radar';
import { useAppSelector } from '../../../app/store/store';
import { selectCommonData } from '../../common';
import ErrorBoundary from '../../../app/pages/error-boundary';

export const getZoom = (pxInMeter: number, latitude: number) => {
  const equatorLengthInMeter = 40075016.686; // 2π ∙ 6378137

  const latitudeLengthInMeter =
    equatorLengthInMeter * Math.cos(latitude * (Math.PI / 180));

  const zoom = Math.log2(latitudeLengthInMeter / pxInMeter) - 8;

  return Number(zoom.toFixed(1));
};

export interface RadarMapProps {
  containerSize: number;
  zoomRatio: number;
  offsetPercent: Coords;
}

function RadarMap(props: RadarMapProps) {
  const { lat, lon } = useAppSelector(selectRadarData);
  const { map_url, grid_range } = useAppSelector(selectCommonData);
  const { containerSize, zoomRatio } = props;
  const pxInMeter = (grid_range * 2 * 1000) / containerSize;
  const zoom = getZoom(pxInMeter, lat);

  const key = JSON.stringify([
    lat,
    lon,
    containerSize,
    grid_range,
    zoom,
    map_url,
    zoomRatio,
  ]);

  if (!map_url) {
    return null;
  }

  return (
    <RadarMapContainer>
      <RadarMapWrapper {...props}>
        <ErrorBoundary>
          <MapContainer
            key={key}
            center={[lat, lon]}
            scrollWheelZoom={false}
            style={{
              width: containerSize * 2.5,
              height: containerSize * 2.5,
              transform: `scale(${zoomRatio})`,
            }}
            zoom={zoom}
            zoomControl={false}
            zoomSnap={0.1}
          >
            <TileLayer url={map_url} />
          </MapContainer>
        </ErrorBoundary>
      </RadarMapWrapper>
    </RadarMapContainer>
  );
}

export default memo(RadarMap);
