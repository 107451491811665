import { RootState } from '../../app/store/store';

export function selectRecordsList(state: RootState) {
  return state.record.data.records;
}

export function selectDownloadRecords(state: RootState) {
  return state.record.data.downloads;
}

export function selectRecord(state: RootState) {
  return state.record.data;
}

export function selectPlayer(state: RootState) {
  return state.record.data.player;
}

export function selectRecordByName(id: string | undefined) {
  return (state: RootState) => {
    if (!id) {
      return undefined;
    }

    return state.record.data.downloads[id];
  };
}
