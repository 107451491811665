import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { ObjectData } from '../../radar';
import { SpeedData } from '../../radar/hook/object-move';

export interface ObjectSpeedProps {
  object_speed: SpeedData;
  selectedObject?: ObjectData;
}

export default function ObjectSpeed({
  selectedObject,
  object_speed,
}: ObjectSpeedProps) {
  const [open, setOpen] = useState(true);
  const { formatMessage } = useIntl();

  const handleClick = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  return (
    <Accordion expanded={open} onChange={handleClick}>
      <AccordionSummary>
        <Typography
          color="primary.contrastText"
          textAlign="end"
          textTransform="uppercase"
          variant="h4"
        >
          {formatMessage({ id: 'common.speed_data' })}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {selectedObject && (
          <>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
            >
              <Typography color="primary.contrastText" variant="body2">
                {selectedObject?.id}
              </Typography>
              <Typography
                color="primary.contrastText"
                textAlign="end"
                variant="h6"
              >
                : {formatMessage({ id: `object.id` })}
              </Typography>
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
            >
              <Typography color="primary.contrastText" variant="body2">
                {selectedObject?.max_speed}
              </Typography>
              <Typography
                color="primary.contrastText"
                textAlign="end"
                variant="h6"
              >
                : {formatMessage({ id: `object.max_speed` })}
              </Typography>
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
            >
              <Typography color="primary.contrastText" variant="body2">
                {selectedObject?.acceleration}
              </Typography>
              <Typography
                color="primary.contrastText"
                textAlign="end"
                variant="h6"
              >
                : {formatMessage({ id: `object.acceleration` })}
              </Typography>
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
            >
              <Typography color="primary.contrastText" variant="body2">
                {selectedObject?.rcs}
              </Typography>
              <Typography
                color="primary.contrastText"
                textAlign="end"
                variant="h6"
              >
                : {formatMessage({ id: `object.rcs` })}
              </Typography>
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
            >
              <Typography color="primary.contrastText" variant="body2">
                {selectedObject?.rad_speed}
              </Typography>
              <Typography
                color="primary.contrastText"
                textAlign="end"
                variant="h6"
              >
                : {formatMessage({ id: `object.rad_speed` })}
              </Typography>
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
            >
              <Typography color="primary.contrastText" variant="body2">
                {selectedObject?.az.toFixed(2)}
              </Typography>
              <Typography
                color="primary.contrastText"
                textAlign="end"
                variant="h6"
              >
                : {formatMessage({ id: `object.az` })}
              </Typography>
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
            >
              <Typography color="primary.contrastText" variant="body2">
                {selectedObject?.altitude.toFixed(2)}
              </Typography>
              <Typography
                color="primary.contrastText"
                textAlign="end"
                variant="h6"
              >
                : {formatMessage({ id: `object.h` })}
              </Typography>
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
            >
              <Typography color="primary.contrastText" variant="body2">
                {selectedObject?.dist_horizon.toFixed(2)}
              </Typography>
              <Typography
                color="primary.contrastText"
                textAlign="end"
                variant="h6"
              >
                : {formatMessage({ id: `object.dist_horizon` })}
              </Typography>
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
            >
              <Typography color="primary.contrastText" variant="body2">
                {`[${selectedObject?.object_x.toFixed(
                  2,
                )}, ${selectedObject?.object_x.toFixed(2)}]`}
              </Typography>
              <Typography
                color="primary.contrastText"
                textAlign="end"
                variant="h6"
              >
                : {formatMessage({ id: `object.xy` })}
              </Typography>
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
            >
              <Typography color="primary.contrastText" variant="body2">
                {String(selectedObject?.is_show)}
              </Typography>
              <Typography
                color="primary.contrastText"
                textAlign="end"
                variant="h6"
              >
                : {formatMessage({ id: `object.is_show` })}
              </Typography>
            </Stack>
          </>
        )}
        <Stack alignItems="center" direction="row" justifyContent="flex-end">
          <Typography color="primary.contrastText" variant="body2">
            {object_speed.speed.toFixed(2)}
          </Typography>
          <Typography color="primary.contrastText" textAlign="end" variant="h6">
            : {formatMessage({ id: 'object.speed' })}
          </Typography>
        </Stack>
        <Stack alignItems="center" direction="row" justifyContent="flex-end">
          <Typography color="primary.contrastText" variant="body2">
            {object_speed.v_xy.toFixed(2)}
          </Typography>
          <Typography color="primary.contrastText" textAlign="end" variant="h6">
            : {formatMessage({ id: 'object.vxy' })}
          </Typography>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
