import { Fragment } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ModalProvider from 'mui-modal-provider';
import { IntlProvider } from 'react-intl';
import AppRouter from './router/app-router';
import { useAppSelector } from './store/store';
import { messages } from '../translations';
import { selectLanguage } from '../features/common';

export default function App() {
  const locale = useAppSelector(selectLanguage);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <IntlProvider
        locale={locale}
        messages={messages[locale]}
        textComponent={Fragment}
      >
        <ModalProvider>
          <AppRouter />
        </ModalProvider>
      </IntlProvider>
    </LocalizationProvider>
  );
}
