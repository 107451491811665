import { Stack, styled } from '@mui/material';

export const Root = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  position: 'relative',
}));

export const SpeedJoystickWrapper = styled('div')({
  position: 'absolute',
  bottom: 45,
  right: 75,
  zIndex: 500,
});

export const HeightJoystickWrapper = styled('div')({
  position: 'absolute',
  bottom: 45,
  left: 75,
  zIndex: 500,
});

export const ObjectSpeedWrapper = styled('div')({
  position: 'absolute',
  top: 5,
  right: 5,
  zIndex: 520,
});

export const RecordWrapper = styled('div')({
  position: 'absolute',
  top: 5,
  right: '50%',
  transform: 'translateX(50%)',
  zIndex: 520,
});

export const InfoWrapper = styled(Stack)({
  position: 'absolute',
  gap: 8,
  top: 5,
  left: 5,
  zIndex: 520,
});
