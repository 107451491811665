import { useTheme } from '@mui/material';
import { memo } from 'react';
import { RADAR_IMAGE_PROPERTIES } from '../constants';

function RadarCenter() {
  const { radarCenter } = RADAR_IMAGE_PROPERTIES;
  const { palette } = useTheme();

  return (
    <g>
      <circle
        cx={radarCenter}
        cy={radarCenter}
        fill={palette.primary.light}
        r={7}
      />
    </g>
  );
}

export default memo(RadarCenter);
