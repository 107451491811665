import services from '../services';
import { RootState, AppDispatch } from './store';

export type ThunkExtraArgument = typeof services;

export interface ThunkApi {
  state: RootState;
  dispatch: AppDispatch;
  extra: ThunkExtraArgument;
}

export type Nullable<T> = T | null;

export enum AsyncRequestState {
  PENDING = 'pending',
  REJECTED = 'rejected',
  FULFILLED = 'fulfilled',
}

export enum AsyncRequestStateActions {
  RESET = 'resetAsyncRequestState',
}

export enum CommonFeatures {
  APP = '@@app',
}
