import { memo, useCallback, useEffect, useRef } from 'react';
import { intervalToDuration, formatDuration } from 'date-fns';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import { PlayerWrapper, Root } from './record-player.styled';
import { useAppDispatch, useAppSelector } from '../../../app/store/store';
import { selectPlayer, selectRecordByName } from '../../record/selectors';
import {
  offRecord,
  onPause,
  onResume,
  onStop,
  incrementStep,
  decrementStep,
} from '../../record/slice';
import { selectThrottle } from '../../common';
import RecordPlayerThrottle from './record-player-throttle';

function RecordPlayer() {
  const dispatch = useAppDispatch();
  const timeout = useRef<NodeJS.Timeout>();
  const { played_file, status, step } = useAppSelector(selectPlayer);
  const record = useAppSelector(selectRecordByName(played_file)) || [];
  const throttle = useAppSelector(selectThrottle);

  useEffect(() => {
    if (status === 'play') {
      timeout.current = setInterval(() => dispatch(incrementStep()), throttle);
    }
    return () => {
      clearInterval(timeout.current);
    };
  }, [status, dispatch, throttle]);

  const maxTime = record.length * throttle;
  const currentTime = step * throttle;
  const duration = intervalToDuration({ start: 0, end: maxTime - currentTime });
  const playPercent = currentTime / (maxTime / 100);
  const formatted = formatDuration(duration, {
    format: ['hours', 'minutes', 'seconds'],
    zero: true,
    delimiter: ':',
    locale: {
      formatDistance: (_token, count) => String(count).padStart(2, '0'),
    },
  });

  const handleOnPause = useCallback(() => {
    dispatch(onPause());
  }, [dispatch]);

  const handleOnResume = useCallback(() => {
    dispatch(onResume());
  }, [dispatch]);

  const handleOnStop = useCallback(() => {
    dispatch(onStop());
  }, [dispatch]);

  const handleOffRecord = useCallback(() => {
    dispatch(offRecord());
  }, [dispatch]);

  const handleIncrementStep = useCallback(() => {
    dispatch(incrementStep());
  }, [dispatch]);

  const handleDecrementStep = useCallback(() => {
    dispatch(decrementStep());
  }, [dispatch]);

  return (
    <Root>
      <PlayerWrapper>
        <Stack
          alignItems="center"
          direction="row"
          gap={1}
          justifyContent="space-between"
        >
          <Typography
            color="primary.contrastText"
            maxWidth={250}
            sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {played_file}
          </Typography>
          <IconButton color="warning" onClick={handleOffRecord} size="small">
            <ExitToAppIcon />
          </IconButton>
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          gap={1}
          justifyContent="space-between"
        >
          <Typography color="primary.contrastText">{formatted}</Typography>
          <RecordPlayerThrottle disabled={status === 'play'} />
          <Stack direction="row" gap={1}>
            {status === 'play' ? (
              <IconButton color="primary" onClick={handleOnPause}>
                <PauseCircleFilledIcon />
              </IconButton>
            ) : (
              <IconButton color="primary" onClick={handleOnResume}>
                <PlayCircleFilledIcon />
              </IconButton>
            )}
            <IconButton color="secondary" onClick={handleDecrementStep}>
              <FastRewindIcon />
            </IconButton>
            <IconButton color="secondary" onClick={handleIncrementStep}>
              <FastForwardIcon />
            </IconButton>
            <IconButton color="error" onClick={handleOnStop}>
              <StopCircleIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Stack>
          <LinearProgress
            color="warning"
            sx={{ borderRadius: 5 }}
            value={playPercent}
            variant="determinate"
          />
        </Stack>
      </PlayerWrapper>
    </Root>
  );
}

export default memo(RecordPlayer);
