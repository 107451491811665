import { styled } from '@mui/material';
import { Coords } from '../../radar';

export interface RadarMapProps {
  containerSize: number;
  zoomRatio: number;
  offsetPercent: Coords;
}

export const RadarMapWrapper = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'containerSize' &&
    prop !== 'zoomRatio' &&
    prop !== 'offsetPercent',
})<RadarMapProps>(({ containerSize, zoomRatio, offsetPercent }) => {
  const size = containerSize * zoomRatio;

  return {
    position: 'absolute',
    top: `calc(-50% + ${
      ((zoomRatio - 1) * containerSize) / 2 - (size / 100) * offsetPercent.y
    }px)`,
    left: `calc(-50% + ${
      ((zoomRatio - 1) * containerSize) / 2 - (size / 100) * offsetPercent.x
    }px)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    width: '200%',
    height: '200%',
    '&:before': {
      content: "''",
      position: 'absolute',
      top: '-4000px',
      left: '-4000px',
      right: '-4000px',
      bottom: '-4000px',
      zIndex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.45)',
    },
    '& .leaflet-right': {
      display: 'none',
    },
  };
});

export const RadarMapContainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
}));
