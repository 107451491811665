import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Autocomplete,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { emulator_types, languages } from '../../common/utils';
import { useAppDispatch } from '../../../app/store/store';
import { EmulatorType, setCommonData } from '../../common';
import Info from '../../../app/components/info';
import { resetData, setSelectedObject } from '../../radar';
import { resetRecordData } from '../../record/slice';

export interface SettingsCommonForm {
  language: string;
  host: string;
  map_url: string;
  throttle: number;
  grid_range: number;
  type: EmulatorType;
  deceleration: number;
}

export default function SettingsCommon(props: SettingsCommonForm) {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { register, control } = useForm<SettingsCommonForm>({
    mode: 'onChange',
    defaultValues: props,
  });

  const formData = useWatch({
    control,
  });

  useEffect(() => {
    dispatch(setCommonData(formData));
  }, [formData, dispatch]);

  return (
    <Box component="form" minHeight={400} py={3}>
      <Stack gap={1}>
        <Stack direction="row" gap={1}>
          <Box flex="1 1">
            <Stack alignItems="flex-end" direction="row" gap={1}>
              <TextField
                fullWidth
                label={formatMessage({ id: 'settings.common.label.throttle' })}
                variant="standard"
                {...register('throttle')}
              />
              <Info
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'bottom',
                }}
                aria-labelledby="shopping-list-info-button"
                id="shopping-list-info-menu"
                style={{
                  color: '#fff',
                }}
                transformOrigin={{
                  horizontal: 'right',
                  vertical: 'top',
                }}
              >
                <Typography color="primary.contrastText" variant="body1">
                  {formatMessage({ id: 'settings.common.info' })}
                </Typography>
              </Info>
            </Stack>
          </Box>
          <Box flex="1 1">
            {' '}
            <Controller
              control={control}
              name="grid_range"
              render={({ field: { onChange, ...otherField } }) => (
                <Autocomplete
                  disableClearable
                  getOptionLabel={(option) => String(option)}
                  onChange={(ev, data) => {
                    onChange(data);
                  }}
                  options={[
                    1.5, 3, 5, 7, 10, 12, 15, 20, 25, 30, 35, 40, 45, 50,
                  ]}
                  popupIcon={<ArrowDropDownIcon color="primary" />}
                  renderInput={(params) => (
                    <TextField
                      label={formatMessage({
                        id: 'settings.common.label.grid_range',
                      })}
                      variant="standard"
                      {...params}
                    />
                  )}
                  {...otherField}
                />
              )}
            />
          </Box>
        </Stack>
        <Box flex="1 1">
          <TextField
            fullWidth
            label={formatMessage({ id: 'settings.common.label.host' })}
            {...register('host')}
            variant="standard"
          />
        </Box>
        <Stack direction="row" gap={1}>
          <Box flex="1 1">
            <TextField
              fullWidth
              label={formatMessage({ id: 'settings.common.label.map' })}
              variant="standard"
              {...register('map_url')}
            />
          </Box>
        </Stack>

        <Stack direction="row" gap={1}>
          <Box flex="1 1">
            <Controller
              control={control}
              name="language"
              render={({ field: { onChange, ...otherField } }) => (
                <Autocomplete
                  disableClearable
                  getOptionLabel={(option) => String(option)}
                  onChange={(_, data) => {
                    onChange(data);
                  }}
                  options={languages}
                  {...otherField}
                  popupIcon={<ArrowDropDownIcon color="primary" />}
                  renderInput={(params) => (
                    <TextField
                      label={formatMessage({
                        id: 'settings.common.label.language',
                      })}
                      variant="standard"
                      {...params}
                    />
                  )}
                />
              )}
            />
          </Box>
          <Box flex="1 1">
            <TextField
              fullWidth
              label={formatMessage({
                id: 'settings.common.label.deceleration',
              })}
              variant="standard"
              {...register('deceleration')}
            />
          </Box>
          <Box flex="1 1">
            <Controller
              control={control}
              name="type"
              render={({ field: { onChange, ...otherField } }) => (
                <Autocomplete
                  disableClearable
                  getOptionLabel={(option) => String(option)}
                  onChange={(_, data) => {
                    onChange(data);
                  }}
                  options={emulator_types}
                  {...otherField}
                  popupIcon={<ArrowDropDownIcon color="primary" />}
                  renderInput={(params) => (
                    <TextField
                      label={formatMessage({
                        id: 'settings.common.label.type',
                      })}
                      variant="standard"
                      {...params}
                    />
                  )}
                />
              )}
            />
          </Box>
        </Stack>

        <Box ml="auto">
          <Button
            color="warning"
            onClick={() => {
              dispatch(resetRecordData());
              dispatch(setSelectedObject(undefined));
              dispatch(resetData());
            }}
            variant="contained"
          >
            {formatMessage({ id: 'settings.common.reset' })}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}
