import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

export interface MuiDialogProps {
  defaultProps?: ComponentsProps['MuiDialog'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiDialog'];
  variants?: ComponentsVariants['MuiDialog'];
}

const MuiDialog: MuiDialogProps = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      '& .MuiBackdrop-root': {
        background: 'rgba(34, 31, 31, 0.8)',
      },
      '& .MuiDialog-container': {
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          alignItems: 'flex-end',
        },
      },
      '& .MuiPaper-root': {
        maxWidth: 600,
        width: '100%',
        margin: 0,
        padding: '17px 30px 23px',
        borderRadius: 12,
        backgroundColor: theme.palette.background.default,
        border: '1px solid rgba(255, 255, 255, 0.07)',
        boxShadow: `0px 2px 22px rgba(0, 0, 0, 0.1)`,
        [theme.breakpoints.down('xl')]: {
          padding: '15px 20px',
        },
        [theme.breakpoints.down('sm')]: {
          padding: '5px',
          borderRadius: '12px 12px 0 0',
        },
      },

      '& .MuiDialogContent-root': {
        marginBottom: 32,
        padding: 0,
        overflowY: 'unset',
        [theme.breakpoints.down('xl')]: {},
      },
      '& .MuiDialogActions-root': {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        '& > button': {
          width: '100%',
          minHeight: 56,
          marginLeft: 0,
          textTransform: 'none',
          boxShadow: 'none',
        },
        '& > button:last-child': {
          marginTop: 8,
        },
      },
    }),
  },
};

export default MuiDialog;
