import { IconButton, Menu, MenuProps } from '@mui/material';
import { useCallback, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styled from '@emotion/styled';

export const MenuStyled = styled(Menu)({
  '& .MuiList-root': {
    padding: 0,
  },
  '& .MuiPaper-root': {
    borderRadius: 10,
    padding: '20px 16px',
    boxShadow: '0px 4px 20px 0px #061D2940',
    maxWidth: 315,
  },
});

export type MenuInterfaceSampling = Omit<
  MenuProps,
  'open' | 'onClose' | 'anchorEl'
>;

export default function Info({
  children,
  id,
  sx,
  className,
  style,
  ...otherProps
}: MenuInterfaceSampling) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  return (
    <>
      <IconButton
        aria-controls={open ? id : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        className={className}
        onClick={handleClick}
        style={style}
        sx={sx}
      >
        <InfoOutlinedIcon />
      </IconButton>
      <MenuStyled
        anchorEl={anchorEl}
        id={id}
        onClose={handleClose}
        open={open}
        {...otherProps}
      >
        {children}
      </MenuStyled>
    </>
  );
}
