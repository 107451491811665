import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider, CssBaseline } from '@mui/material';
import vhCheck from 'vh-check';
import { App, store, theme } from './app';
import 'react-toastify/dist/ReactToastify.css';
import 'leaflet/dist/leaflet.css';
import './global.css';
import { StyledToastContainer } from './app/components/tostify.styled';
import ErrorBoundary from './app/pages/error-boundary';

vhCheck();

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <CssBaseline />
          <App />
          <StyledToastContainer pauseOnHover={false} />
        </Provider>
      </ThemeProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
