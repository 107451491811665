import { createTheme } from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import palette from './palette';
import breakpoints from './breakpoints';
import fonts from './fonts';
import {
  MuiButton,
  MuiAutocomplete,
  MuiTextField,
  MuiInputLabel,
  MuiSwitch,
  MuiFormControlLabel,
  MuiPaper,
  MuiFab,
  MuiTypography,
  MuiIconButton,
  MuiAccordion,
  MuiDialog,
  MuiTabs,
} from './components';

const theme = createTheme({
  palette,
  breakpoints,
  typography: {
    fontFamily: 'Ubuntu, sans-serif',
  },
  components: {
    MuiButton,
    MuiAutocomplete,
    MuiTextField,
    MuiInputLabel,
    MuiSwitch,
    MuiFormControlLabel,
    MuiPaper,
    MuiFab,
    MuiTypography,
    MuiIconButton,
    MuiAccordion,
    MuiDialog,
    MuiTabs,
    ...fonts,
    MuiCssBaseline: {
      styleOverrides: {
        // body: {
        //   '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        //     backgroundColor: '#272727',
        //   },
        //   '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        //     // borderRadius: 8,
        //     backgroundColor: '#6b6b6b',
        //     // minHeight: 24,
        //     // border: '3px solid #2b2b2b',
        //   },
        //   '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
        //     {
        //       backgroundColor: '#959595',
        //     },
        //   '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
        //     {
        //       backgroundColor: '#959595',
        //     },
        //   '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
        //     {
        //       backgroundColor: '#959595',
        //     },
        //   '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        //     backgroundColor: '#2b2b2b',
        //   },
        // },
      },
    },
  },
});

export default theme;
