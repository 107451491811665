import { Paper, PaperProps, useMediaQuery, useTheme } from '@mui/material';
import { useRef } from 'react';
import Draggable from 'react-draggable';

export default function DraggablePaper({ ...props }: PaperProps) {
  const { breakpoints } = useTheme();
  const isDownSm = useMediaQuery(breakpoints.down('sm'));
  const paperRef = useRef(null);

  return (
    <Draggable
      cancel={'[class*="MuiDialogContent-root"]'}
      disabled={isDownSm}
      enableUserSelectHack={false}
      handle="#draggable-dialog"
      nodeRef={paperRef}
    >
      <Paper sx={{ cursor: 'grab' }} {...props} ref={paperRef} />
    </Draggable>
  );
}
