import { memo, useEffect } from 'react';
import { Box, IconButton, Stack, TextField } from '@mui/material';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { AreasData, rewriteAreas, selectAreas } from '../../radar';
import { useAppDispatch, useAppSelector } from '../../../app/store/store';
import { calculateAzimuthFromCoords } from '../../radar/utils';

export interface AreasFormData extends AreasData {
  uid: string;
}

export interface SettingsAreasForm {
  areas: AreasFormData[];
}

export interface SettingsAreasProps {
  grid_range: number;
}

function SettingsAreas({ grid_range }: SettingsAreasProps) {
  const dispatch = useAppDispatch();
  const areas = useAppSelector(selectAreas);

  const { control, register } = useForm<SettingsAreasForm>({
    mode: 'onChange',
    defaultValues: {
      areas: areas.map((area) => ({
        ...area,
        uid: area.id,
      })),
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'areas',
  });

  const formData = useWatch({
    control,
  });

  function getId() {
    const currentTime = Math.round(new Date().getTime() / 100).toString();
    const subSting = currentTime.substring(5);
    return subSting;
  }

  useEffect(() => {
    dispatch(
      rewriteAreas(
        formData.areas?.map((area) => ({
          ...area,
          ...calculateAzimuthFromCoords(area.object_x, area.object_y),
          id: area.uid,
        })),
      ),
    );
  }, [formData, dispatch, grid_range]);

  return (
    <Stack component="form" gap={2} minHeight={400} py={3}>
      <SimpleBar style={{ maxHeight: 300, paddingRight: 20 }}>
        <Stack gap={2}>
          {fields.map((field, index) => (
            <Stack key={field.id} borderBottom="1ps solid #eee" gap={1}>
              <Box flex="1 1">
                <Stack direction="row" gap={1}>
                  <TextField
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    label="ID"
                    {...register(`areas.${index}.uid`)}
                  />

                  <TextField
                    fullWidth
                    label="X, m"
                    {...register(`areas.${index}.object_x`, {
                      valueAsNumber: true,
                    })}
                  />
                  <TextField
                    fullWidth
                    label="Y, m"
                    {...register(`areas.${index}.object_y`, {
                      valueAsNumber: true,
                    })}
                  />
                  <TextField
                    fullWidth
                    label="Radius, m"
                    {...register(`areas.${index}.radius`, {
                      valueAsNumber: true,
                    })}
                  />
                </Stack>
              </Box>
              <Box flex="1 1">
                <Stack
                  alignItems="flex-end"
                  direction="row"
                  gap={1}
                  justifyContent="flex-end"
                >
                  <IconButton color="error" onClick={() => remove(index)}>
                    <DeleteForeverIcon />
                  </IconButton>
                </Stack>
              </Box>
            </Stack>
          ))}
        </Stack>
      </SimpleBar>
      <IconButton
        color="primary"
        onClick={() => {
          const object_x = 0;
          const object_y = 100;

          append({
            uid: getId(),
            id: getId(),
            object_x,
            object_y,
            radius: 500,
            ...calculateAzimuthFromCoords(object_x, object_y),
          });
        }}
      >
        <PlaylistAddIcon />
      </IconButton>
    </Stack>
  );
}

export default memo(SettingsAreas);
